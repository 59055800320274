/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid } from '@material-ui/core';
import React from 'react';

import { withRouter } from 'react-router-dom';

import LogoBakeca from '../../res/images/logo-bakeca.png';

import './styles.css';


const Footer = () => (
  <div
    className="footer"
  >


    <Grid container className="first-row-wrapper">
      <Grid item xs={12} md={6} className="collab-wrapper">
        <div>Un progetto in collaborazione con </div>
        <div className="bakeca-logo-wrapper">
          <img src={LogoBakeca} alt="logo bakeca" />
        </div>
      </Grid>
      <Grid item xs={12} md={6} className="links-wrapper">
        <ul>
          <li>
            <a href="/docs/condizioni_contrattuali_fornitori.pdf" target="_blank">Condizioni contrattuali fornitori</a>
          </li>
          <li>
            <a target="_blank" href="/docs/contratto_tra_aderenti.pdf">Contratto tra aderenti</a>
          </li>
          <li>
            <a target="_blank" href="/docs/contratto_tra_aderenti.pdf">Termini e condizioni</a>
          </li>
          <li>
            <a target="_blank" href="/docs/contratto_tra_aderenti.pdf">Informativa sulla privacy</a>
          </li>
        </ul>
      </Grid>
    </Grid>


    <p>© 2021 and_dna srls - P.IVA 12060520017</p>
    <p>
      VIA STAMPATORI 4 – 10122 – TORINO (TO)
      <br />
      info@and-dna.it
      <br />
      Servizio Clienti : +39 348 70 15 246
    </p>

  </div>
);

export default withRouter(Footer);
