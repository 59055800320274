/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Link,
} from 'react-router-dom';
import { setDefaultLocale } from 'react-datepicker';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import Main from './containers/main';
import TopBar from './components/top-bar';
import { fetchCategoriesServices, fetchServices } from './store/actions';


import SearchRoutes from './routes/search';
import SupplierRoutes from './routes/supplier';
import RegistrationRoutes from './routes/registration';
import DashboardRoutes from './routes/dashboard';
import PasswordResetRoutes from './routes/password-reset';
import Loader from './components/loader';
import Footer from './components/footer';
import HowItWorks from './containers/static/how-it-works';
import WhoWeAre from './containers/static/who-we-are/WhoWeAre';

setDefaultLocale('es');


const MySerenCareRouter = ({
  mainLoading, services, serviceCategories, fetchServices, fetchCategoriesServices,
}) => {
  useEffect(() => {
    if (services === undefined) {
      fetchServices();
    }

    if (serviceCategories === undefined) {
      fetchCategoriesServices();
    }
  }, []);

  // if ((isMobile || isTablet) && !window.location.pathname.includes('password-reset')) {
  //   return (
  //     <div className="reservation-status-wrapper" style={{ textAlign: 'center' }}>
  //       <h3>Scarica l'app!</h3>
  //       <p>Stai visitando la piattaforma da un dispositivo mobile,
  // ti preghiamo di scaricare la nostra app</p>

  //       <a href="https://apps.apple.com/it/app/myserencare/id1520213104?l">
  //         <img
  //           style={{ width: 174 }}
  //           src="https://linkmaker.itunes.apple.com/images/badges/it-it/badge_appstore-lrg.svg"
  //           alt="AppStore"
  //         />
  //       </a>

  //       <a href="https://play.google.com/store/apps/details?id=it.and_dna.myserencare&hl=it ">
  //         <img
  //           style={{ width: 200 }}
  //           src="https://play.google.com/intl/en_us/badges/static/images/badges/it_badge_web_generic.png"
  //           alt="Google PlayStore"
  //         />
  //       </a>
  //     </div>
  //   );
  // }

  if (mainLoading) {
    return <Loader />;
  }

  return (
    <Router>
      <TopBar />

      <div style={{ minHeight: '80vh' }}>
        <Switch>
          <Route path="/ricerca">
            <SearchRoutes />
          </Route>
          <Route path="/fornitore">
            <SupplierRoutes />
          </Route>

          <Route path="/registrazione">
            <RegistrationRoutes />
          </Route>

          <Route path="/dashboard">
            <DashboardRoutes />
          </Route>


          <Route path="/prenotazione-customer-service-confermata">
            <div className="reservation-status-wrapper">
              <h3>Richiesta inviata!</h3>
              <p>Verrai presto contattato dal nostro Customer service!</p>
            </div>
          </Route>


          <Route path="/registrazione-confermata">
            <div className="reservation-status-wrapper">
              <h3>Account confermato!</h3>
              <p>Inizia ad utilizzare My Seren Care!</p>
              <div style={{ textAlign: 'center', marginTop: 60 }}>
                <Link to="/dashboard" className="btn btn-yellow">Vai alla homepage</Link>
              </div>
            </div>
          </Route>

          <Route path="/password-reset">
            <PasswordResetRoutes />
          </Route>

          <Route path="/come-funziona">
            <HowItWorks />
          </Route>

          <Route path="/chi-siamo">
            <WhoWeAre />
          </Route>

          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </div>

      <Footer />
    </Router>
  );
};


const mapStateToProps = (state) => ({
  mainLoading: state.mainLoading,
  services: state.services,
  serviceCategories: state.serviceCategories,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCategoriesServices,
  fetchServices,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MySerenCareRouter);
