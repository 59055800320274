/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Modal } from 'react-bootstrap';

import Loader from '../loader';

const CustomSelectInput = ({
  id, title, error, onFocus, onChange = () => {}, onBlur = () => {}, options, ...props
}) => {
  const [preventFocus, setPreventFocus] = useState(false);
  const [modalVisible, setModalVisibile] = useState();

  const handleOnFocus = (e) => {
    e.preventDefault();

    if (!preventFocus) {
      setModalVisibile(true);
      setPreventFocus(true);

      if (onFocus) {
        onFocus();
      }
    } else {
      setPreventFocus(false);
    }
  };

  const handleClick = (option, event) => {
    event.persist();
    onChange(option);
    setModalVisibile(false);

    setTimeout(() => {
      document.activeElement.blur();
      onBlur(event);
    }, 400);
  };

  return (
    <div className="custom-input-wrapper">
      <TextField
        {...props}
        onFocus={handleOnFocus}
        InputProps={{
          readOnly: true,
        }}
      />
      {error !== undefined && <label className="error-label" htmlFor={id}>{error}</label>}

      <Modal show={modalVisible} onHide={() => setModalVisibile(false)} backdrop="static">
        <Modal.Header style={{ borderWidth: 0 }} closeButton>
          <h5>{title}</h5>
        </Modal.Header>
        <Modal.Body className="reservation-from-modal-content">
          {options === undefined ? (
            <Loader />
          ) : (
            <div className="options-wrapper">
              <ul>
                {options.map((o) => (
                  <li key={`key_${o}`} onClick={(event) => handleClick(o, event)}>{o}</li>
                ))}
              </ul>
            </div>
          )}

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomSelectInput;
