/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import Axios from 'axios';
import Swal from 'sweetalert2';

export * from './user.actions';
export * from './services.actions';
export * from './search.actions';
export * from './suppliers.actions';
export * from './common.actions';

export const baseURL = process.env.REACT_APP_API_URL;

export class Api {
  constructor() {
    if (Api.instance) {
      return Api.instance;
    }

    Api.instance = this;

    this.api = Axios.create({
      baseURL,
    });

    return this;
  }

    setAccessToken = (token) => {
      this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    reset = async () => {
      this.api = Axios.create({
        baseURL,
      });
    }

    call = async ({
      method,
      data,
      url,
      actionType,
      action,
    }) => {
      try {
        const resp = await this.api.request({
          url,
          method: method || 'get',
          data,
        });


        if (resp.status < 400) {
          return {
            type: actionType !== undefined ? `${actionType}_SUCCESS` : 'api/REQUEST_SUCCESS',
            payload: resp,
            onSuccess: action ? action.onSuccess : undefined,
            onError: action ? action.onError : undefined,
          };
        }

        return {
          type: actionType !== undefined ? `${actionType}_FAIL` : 'api/REQUEST_FAIL',
          payload: resp,
        };
      } catch (err) {
        if (err.request !== undefined && err.request.response !== undefined && JSON.parse(err.request.response).message === 'Unauthenticated.') {
          Swal.fire('Errore', 'Sessione scaduta', 'error');

          return {
            type: 'UNAUTHENTICAED_ERROR',
            payload: err.response || err.message,
          };
        }
        return {
          type: actionType !== undefined ? `${actionType}_FAIL` : 'api/REQUEST_FAIL',
          payload: err.response || err.message,
        };
      }
    }
}

export const urlSerializeFilters = (obj) => {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(
        `${`${encodeURIComponent(p)}`
                  + '='}${
          encodeURIComponent(obj[p])}`,
      );
    }
  }
  return str.join('&');
};
