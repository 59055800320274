/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Navbar, Button, Nav, Container,
} from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { isMobile, isTablet } from 'react-device-detect';

import LoginModal from '../login-modal';
import images from '../../res/images';
import './TopBar.css';
import ErrorHandler from '../error-handler';
import UserIconTopBar from '../user-icon-topbar';

const TopBar = ({
  user, fetchUserSelf, history, fetchAddresses,
}) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const items = history.location.pathname.split('/');
    if (user !== undefined && user.data !== undefined && user.data.type === 'supplier' && items[1] !== 'dashboard' && items[1] !== 'chi-siamo' && items[1] !== 'come-funziona') {
      history.push('/dashboard');
    }

    setExpanded(false);
  }, [history.location]);

  useEffect(() => {
    if (user && user.access_token) {
      if (!('data' in user)) {
        fetchUserSelf();
      } else {
        fetchAddresses();

        const { missing_data: missingData } = user.data;
        if (missingData) {
          if (missingData.includes('needy')) {
            history.push('/registrazione/familiare/anziano');
          }
        }
      }
    }
  }, [user]);

  const renderNavs = useCallback(() => {
    if (user) {
      return (
        <Nav>
          <a href="/come-funziona" className="nav-link">Come funziona</a>
          <a href="/chi-siamo" className="nav-link">Chi siamo</a>
          <Link to="/dashboard/richieste" className="nav-link">Richieste</Link>
          <Link to="/dashboard/prestazioni" className="nav-link">Prestazioni</Link>
          <Link to="/dashboard" className="nav-link">Area personale</Link>
          {/* <NotificationBell /> */}
          <UserIconTopBar />
        </Nav>
      );
    }

    return (
      <Nav>
        <a href="/come-funziona" className="nav-link">Come funziona</a>
        <a href="/chi-siamo" className="nav-link">Chi siamo</a>
        <Link onClick={() => setShowLoginModal(true)} className="nav-link">Accedi</Link>
        <Link to="/registrazione" className="nav-link"><Button variant="yellow">Registrati</Button></Link>
      </Nav>
    );
  }, [user]);

  const handleExpand = React.useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  return (
    <>
      <Box boxShadow={4}>
        <Container>
          <Navbar expanded={expanded} collapseOnSelect expand="lg">
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}

            <Navbar.Brand>
              <Link to="/" className="nav-link">
                <img className="logo" src={images.logo} alt="Elder" />
              </Link>
            </Navbar.Brand>
            {isMobile || isTablet ? <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleExpand} /> : null}
            <Navbar.Collapse className="navWrapper" id="basic-navbar-nav">
              {renderNavs()}
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </Box>
      <LoginModal
        visible={showLoginModal}
        onClose={() => {
          setShowLoginModal(false);
        }}
      />

      <ErrorHandler />
    </>
  );
};

export default withRouter(TopBar);
