/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, Container } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

import CustomInput from '../../../components/custom-input';
import './style.css';
import CustomCheckbox from '../../../components/custom-checkbox';
import Loader from '../../../components/loader';
import colors from '../../../res/colors';
import {
  getSupplierValidationSchema,
  getUserValidationSchema,
} from './validations';

const cd = new Date();
const years = [...Array(120).keys()].map((item, index) => cd.getFullYear() - index);
const months = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];

const RegistrationNeedy = ({
  loading, genders, type, registerUser, history, fetchGenders,
}) => {
  const [typeString, setTypeString] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {
    fetchGenders();
  }, []);

  useEffect(() => {
    let str = '';
    if (type === 'needy') { str = 'Anziano'; }
    if (type === 'carer') { str = 'Familiare'; }
    if (type === 'supplier') { str = 'Professionista'; }
    setTypeString(str);
  }, [type]);

  const handleConfirm = (values) => {
    const data = {
      type,
      first_name: values.first_name,
      last_name: values.last_name,
      gender: values.gender,
      cod_fisc: values.cod_fisc,
      address,
      email: values.email,
      email_confirmation: values.email_confirm,
      phone_number: values.phone,
      mobile_number: values.mobile_number,
      password: values.password,
      password_confirmation: values.password_confirm,
      birthday: moment(values.birthday, 'DD/MM/YYYY').format(
        'DD-MM-YYYY',
      ),
    };

    if (type === 'supplier') {
      data.rag_soc = values.rag_soc;
      data.piva = values.piva;
      data.iban = values.iban;
      data.cod_fisc_supplier = values.cod_fisc_supplier;
    }

    registerUser(data, () => {
      window.scrollTo(0, 0);
      history.push('/registrazione/conferma-email');
    });
  };

  if (loading) {
    return <Loader />;
  }

  
  const ToInputCodiceFiscaleUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  return (
    <div className="registration-needy-wrapper">
      <Container className="categories-explore">
        <Container container>
          <h3>{`Registrazione ${typeString}`}</h3>
          <Formik
            validationSchema={type === 'supplier' ? getSupplierValidationSchema({ genders }) : getUserValidationSchema({ genders })}
            validateOnChange
            initialValues={{
              first_name: '',
              last_name: '',
              gender: '',
              email: '',
              email_confirm: '',
              policy: false,
              password: '',
              password_confirm: '',
              birthday: moment().subtract(60, 'year').format('DD/MM/YYYY'),
              address: '',
              privacy: false,
              cod_fisc: '',
              mobile_number: '',
              phone_number: '',
            }}
            onSubmit={handleConfirm}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              console.log('errors', errors);
              return (
                <form style={{ marginTop: 30 }} onSubmit={handleSubmit} autoComplete="off">

                  <Grid container>

                    {type === 'supplier' && (
                      <>
                        <Grid item xs={12} md={6}>
                          <div className="form-input-wrapper">
                            <CustomInput
                              type="text"
                              required
                              onChange={handleChange('rag_soc')}
                              onBlur={handleBlur('rag_soc')}
                              value={values.rag_soc}
                              id="rag_soc"
                              label="Ragione sociale"
                              variant="outlined"
                              error={touched.rag_soc && errors.rag_soc}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className="form-input-wrapper">
                            <CustomInput
                              type="text"
                              onChange={handleChange('iban')}
                              onBlur={handleBlur('iban')}
                              value={values.iban}
                              id="iban"
                              label="Iban"
                              variant="outlined"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className="form-input-wrapper">
                            <CustomInput
                              type="text"
                              required
                              onChange={handleChange('piva')}
                              onBlur={handleBlur('piva')}
                              value={values.piva}
                              id="piva"
                              label="Partita iva"
                              variant="outlined"
                              error={touched.piva && errors.piva}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className="form-input-wrapper">
                            <CustomInput
                              type="text"
                              required
                              onChange={handleChange('cod_fisc_supplier')}
                              onBlur={handleBlur('cod_fisc_supplier')}
                              value={values.cod_fisc_supplier}
                              id="cod_fisc_supplier"
                              label="Codice fiscale azienda"
                              onInput={ToInputCodiceFiscaleUppercase}
                              variant="outlined"
                              error={touched.cod_fisc_supplier && errors.cod_fisc_supplier}
                            />
                          </div>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          type="text"
                          required
                          onChange={handleChange('first_name')}
                          onBlur={handleBlur('first_name')}
                          value={values.first_name}
                          id="first_name"
                          label="Nome"
                          variant="outlined"
                          error={touched.first_name && errors.first_name}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          type="text"
                          required
                          onChange={handleChange('last_name')}
                          onBlur={handleBlur('last_name')}
                          value={values.last_name}
                          id="last_name"
                          label="Cognome"
                          variant="outlined"
                          error={touched.last_name && errors.last_name}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          type="select"
                          title="Seleziona il tuo sesso"
                          options={genders && genders.map((g) => g.name)}
                          required
                          onChange={handleChange('gender')}
                          onBlur={handleBlur('gender')}
                          value={values.gender}
                          id="gender"
                          label="Sesso"
                          variant="outlined"
                          error={touched.gender && errors.gender}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="datepicker-input-wrapper" style={{ marginLeft: 20, marginRight: 20 }}>
                        <DatePicker
                        required
                          style={{ width: '100%' }}
                          dateFormat="dd/MM/Y"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <button
                                style={{
                                  border: 0, color: colors.secondary.normal, fontWeight: 'bold', marginRight: 10,
                                }}
                                type="button"
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              >
                                {'<'}
                              </button>
                              <select
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) => changeYear(value)}
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) => {
                                  changeMonth(months.indexOf(value));
                                }}
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <button
                                style={{
                                  border: 0, color: colors.secondary.normal, fontWeight: 'bold', marginLeft: 10,
                                }}
                                type="button"
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              >
                                {'>'}
                              </button>
                            </div>
                          )}
                          selected={moment(values.birthday, 'DD/MM/YYYY')._d}
                          onChange={(date) => handleChange('birthday')(moment(date).format('DD/MM/YYYY'))}
                          customInput={(
                            <CustomInput
                              value={values.birthday}
                              id="birthday"
                              label="Data di nascita"
                              variant="outlined"
                              contentEditable={false}
                              error={touched.birthday && errors.birthday}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          required
                          title="Inserisci e seleziona il tuo indirizzo di residenza"
                          type="address"
                          onChange={(a) => {
                            handleChange('address')(`${a.address}, ${a.postalCode} ${a.city}`);
                            setAddress(a);

                            // handleChange('address')
                          }}
                          onBlur={handleBlur('address')}
                          value={values.address}
                          id="address"
                          label="Indirizzo di residenza"
                          variant="outlined"
                          error={touched.address && errors.address}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          required
                          onChange={handleChange('cod_fisc')}
                          onBlur={handleBlur('cod_fisc')}
                          value={values.cod_fisc}
                          id="cod_fisc"
                          label={type === 'supplier' ? 'Codice fiscale persona fisica' : 'Codice fiscale'}
                          variant="outlined"
                          onInput={ToInputCodiceFiscaleUppercase}
                          error={touched.cod_fisc && errors.cod_fisc}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          onChange={handleChange('phone_number')}
                          onBlur={handleBlur('phone_number')}
                          value={values.phone_number}
                          id="phone_number"
                          label="Telefono fisso"
                          variant="outlined"
                          error={touched.phone_number && errors.phone_number}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          required
                          onChange={handleChange('mobile_number')}
                          onBlur={handleBlur('mobile_number')}
                          value={values.mobile_number}
                          id="mobile_number"
                          label="Telefono cellulare"
                          variant="outlined"
                          error={touched.mobile_number && errors.mobile_number}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          required
                          onChange={handleChange('email')}
                          onBlur={handleBlur('email')}
                          value={values.email}
                          id="email"
                          label="Email"
                          variant="outlined"
                          error={touched.email && errors.email}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          required
                          onChange={handleChange('email_confirm')}
                          onBlur={handleBlur('email_confirm')}
                          value={values.email_confirm}
                          error={touched.email_confirm && errors.email_confirm}
                          id="email_confirm"
                          label="Conferma email"
                          variant="outlined"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          required
                          onChange={handleChange('password')}
                          onBlur={handleBlur('password')}
                          value={values.password}
                          id="password"
                          type="password"
                          label="Password"
                          variant="outlined"
                          error={touched.password && errors.password}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-input-wrapper">
                        <CustomInput
                          required
                          onChange={handleChange('password_confirm')}
                          onBlur={handleBlur('password_confirm')}
                          value={values.password_confirm}
                          id="password_confirm"
                          type="password"
                          label="Conferma password"
                          variant="outlined"
                          error={touched.password_confirm && errors.password_confirm}
                        />
                      </div>
                    </Grid>

                    <div className="policy-checkbox-wrapper">

                      {type === 'supplier' ? (
                        <div>
                          <CustomCheckbox id="privacy" checked={values.privacy} onChange={handleChange('privacy')} />
                          <label htmlFor="privacy">
                            Dichiaro di aver letto ed approvato le
                            {' '}
                            <a target="_blank" rel="noopener noreferrer" href="/docs/condizioni_contrattuali_fornitori.pdf">condizioni contrattuali dei fornitori e termini condizioni privacy</a>
                          </label>
                        </div>
                      ) : (
                        <div>
                          <CustomCheckbox id="privacy" checked={values.privacy} onChange={handleChange('privacy')} />
                          <label htmlFor="privacy">
                            Dichiaro di aver letto ed approvato il
                            {' '}
                            <a target="_blank" rel="noopener noreferrer" href="/docs/contratto_tra_aderenti.pdf">contratto di adesione al servizio e termini condizioni privacy</a>
                          </label>
                        </div>
                      )}

                      <div>
                        {errors.privacy !== undefined && <label className="error-label" htmlFor="privacy">{errors.privacy}</label>}
                      </div>
                    </div>

                  </Grid>

                  <div style={{ textAlign: 'right' }}>
                    <Button disabled={Object.keys(errors).length > 0} type="submit" onClick={handleSubmit} variant="yellow">Continua</Button>
                  </div>
                </form>
              );
            }}
          </Formik>

        </Container>
      </Container>

    </div>
  );
};

export default withRouter(RegistrationNeedy);
