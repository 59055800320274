/* eslint-disable import/prefer-default-export */
import Swal from 'sweetalert2';
import {
  Api,
  showMessage,
  SUPPLIER_FETCH,
  FETCH_TIMESLOTS,
  NEW_SERVICE_REQUEST,
  SUPPLIER_SEND_DOCUMENTS,
  fetchUserSelf,
  SUPPLIER_UPDATE_MAX_DISTANCE,
  SUPPLIER_UPDATE_TIMETABLE,
  SUPPLIER_ADD_CONTACT,
  SUPPLIER_DELETE_CONTACT,
} from '../actions';

const api = new Api();

export const suppliersMiddleware = (store) => (next) => (action) => {
  const middlewareAction = {};

  middlewareAction[SUPPLIER_FETCH] = async () => {
    store.dispatch(await api.call({
      url: `/suppliers/${action.payload.supplier_id}`,
      actionType: action.type,
    }));
  };

  middlewareAction[FETCH_TIMESLOTS] = async () => {
    store.dispatch(await api.call({
      url: `/suppliers/${action.payload.supplier_id}/timeslots?serviceId=${action.payload.service_id}&date=${action.payload.date}`,
      actionType: action.type,
    }));
  };

  middlewareAction[NEW_SERVICE_REQUEST] = async () => {
    store.dispatch(await api.call({
      url: '/services/new-request',
      method: 'post',
      data: action.payload,
      actionType: action.type,
    }));
  };

  middlewareAction[`${NEW_SERVICE_REQUEST}_SUCCESS`] = async () => {
    store.dispatch(showMessage('Il nuovo servizio è stato richiesto con successo. Verrai avvisato tramite email qualora venisse reso disponibile'));
  };

  middlewareAction[SUPPLIER_SEND_DOCUMENTS] = async () => {
    const {
      front, back, onSuccess, onError,
    } = action.payload;

    const data = new FormData();
    data.append('front', front);
    data.append('back', back);

    const respAction = await api.call({
      url: '/me/upload-documents',
      method: 'post',
      data,
      actionType: action.type,
      action,
    });

    if (respAction.type === `${SUPPLIER_SEND_DOCUMENTS}_SUCCESS` && onSuccess !== undefined) {
      onSuccess();
    } else if (onError) {
      onError();
    }

    store.dispatch(respAction);
  };

  middlewareAction[`${SUPPLIER_SEND_DOCUMENTS}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    Swal.fire('Ok!', 'Documento caricato con successo.', 'success');
  };

  middlewareAction[`${SUPPLIER_SEND_DOCUMENTS}_FAIL`] = async () => {
    Swal.fire('Errore', 'Errore durante il caricamento del documento', 'error');
  };

  middlewareAction[SUPPLIER_UPDATE_MAX_DISTANCE] = async () => {
    const respAction = await api.call({
      url: '/me/update-max-distance',
      data: { max_distance: action.payload },
      method: 'post',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${SUPPLIER_UPDATE_MAX_DISTANCE}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    Swal.fire('Ok!', 'Distanza massima percorribile modificata con successo.', 'success');
  };

  middlewareAction[SUPPLIER_UPDATE_TIMETABLE] = async () => {
    const respAction = await api.call({
      url: '/me/update-timetable',
      data: { timetable: action.payload },
      method: 'post',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${SUPPLIER_UPDATE_TIMETABLE}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    Swal.fire('Ok!', 'Disponibilità aggiornata correttamente.', 'success');
  };

  middlewareAction[SUPPLIER_ADD_CONTACT] = async () => {
    const respAction = await api.call({
      url: '/me/contacts',
      data: action.payload,
      method: 'post',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${SUPPLIER_ADD_CONTACT}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    Swal.fire('Ok!', action.payload.data.message, 'success');
  };

  middlewareAction[SUPPLIER_DELETE_CONTACT] = async () => {
    const respAction = await api.call({
      url: '/me/contacts',
      data: action.payload,
      method: 'delete',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${SUPPLIER_DELETE_CONTACT}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    Swal.fire('Ok!', action.payload.data.message, 'success');
  };

  next(action);

  if (middlewareAction[action.type]) { middlewareAction[action.type](); }
};
