import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddPaymentModal from './AddPaymentModal';

import { throwError, addPaymentMethods } from '../../../../../store/actions';

const mapStateToProps = () => ({});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  throwError,
  addPaymentMethods,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPaymentModal);
