/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { Modal } from 'react-bootstrap';
import UnloggedPreReservationStepFrom from './UnloggedPreReservationStepFrom';
import LoggedPreReservationStepFrom from './LoggedPreReservationStepFrom';

import {
  addSearchOption, performSearch,
} from '../../store/actions';

const PreReservationStepForm = ({
  visible, onClose, user, addSearchOption, performSearch, addresses, ...props
}) => (
  <Modal show={visible} onHide={onClose} backdrop="static">
    {user
      ? (
        <LoggedPreReservationStepFrom
          addresses={addresses}
          user={user}
          performSearch={performSearch}
          addSearchOption={addSearchOption}
          {...props}
        />
      )
      : (
        <UnloggedPreReservationStepFrom
          performSearch={performSearch}
          addSearchOption={addSearchOption}
          {...props}
        />
      )}
  </Modal>
);

const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.loading,
  services: state.services,
  serviceCategories: state.serviceCategories,
  addresses: state.addresses,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addSearchOption,
  performSearch,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreReservationStepForm);
