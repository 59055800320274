import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SecuritySettings from './SecuritySettings';

import { updateUserData } from '../../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateUserData,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecuritySettings);
