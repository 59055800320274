import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/loader';
import ReservationRequestRow from '../../../components/reservation-request-row';

import './style.css';
import ReservationRequestDetail from '../../../components/reservation-request-detail';
import CustomInput from '../../../components/custom-input';
import { getStatus } from '../../../libs/utils';


const ReservationRequests = ({
  user, reservationRequests, fetchUserReservationRequests,
}) => {
  const [filteredReservationRequests, setFilteredReservationRequests] = useState();
  const [showReservationRequestDetail, setShowReservationRequestDetail] = useState(false);
  const [selectedReservationRequest, setSelectedReservationRequest] = useState();
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchUserReservationRequests();
  }, []);

  useEffect(() => {
    if (selectedReservationRequest) {
      setShowReservationRequestDetail(true);
    } else {
      setShowReservationRequestDetail(false);
    }
  }, [selectedReservationRequest]);

  useEffect(() => {
    if (Object.keys(filters).length > 0 && reservationRequests !== undefined) {
      setFilteredReservationRequests(reservationRequests.filter((e) => {
        if (filters.selectedNeedy && filters.selectedNeedy !== 'Tutti') {
          if ((e !== undefined && e.needy.full_name !== filters.selectedNeedy)
          || (e.needy !== undefined && e.needy.full_name !== filters.selectedNeedy)) {
            return false;
          }
        }

        if (filters.selectedStatus) {
          const status = getStatus(e);
          if (filters.selectedStatus === 'Rifiutato' && status.value !== 'refused') return false;
          if (filters.selectedStatus === 'In fase di valutazione' && status.value !== 'pending') return false;
          if (filters.selectedStatus === 'Cancellato' && status.value !== 'deleted') return false;
        }

        return true;
      }));
    }
  }, [reservationRequests, filters]);

  // useEffect(() => {
  //   if (reservationRequests) {
  //     const pending = [];
  //     const refused = [];
  //     const past = [];

  //     reservationRequests.forEach((reservationRequest) => {
  //       if (filters.selectedNeedy !== undefined &&
  // reservationRequest.needy.id !== filters.selectedNeedy.id) return;

  //       const date = moment(reservationRequest.end_date_time);

  //       if (date.isBefore(moment())) {
  //         past.push(reservationRequest);
  //       } else if (reservationRequest.status.value === 'pending') {
  //         pending.push(reservationRequest);
  //       } else {
  //         refused.push(reservationRequest);
  //       }

  //       setPendingReservations(pending);
  //       setRefusedReservations(refused);
  //       setPastReservations(past);
  //     });
  //   }
  // }, [reservationRequests, filters]);

  const renderFilters = () => (
    <div className="filters-wrapper">
      <h6>Filtri</h6>

      <Grid container>


        {'needy' in user.data && (
          <Grid item xs={12} md={4}>
            <div className="form-input-wrapper">
              <CustomInput
                type="select"
                title="Seleziona l'anziano"
                options={['Tutti', ...user.data.needy.map((g) => g.full_name)]}
                onChange={(item) => setFilters({ ...filters, selectedNeedy: item })}
                value={filters.selectedNeedy || 'Tutti'}
                id="needy"
                label="Anziano"
                variant="outlined"
              />
            </div>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <div className="form-input-wrapper">
            <CustomInput
              type="select"
              title="Seleziona lo stato degli eventi che vuoi vedere"
              options={['Rifiutato', 'In fase di valutazione', 'Cancellato']}
              onChange={(item) => setFilters({ ...filters, selectedStatus: item })}
              value={filters.selectedStatus || 'Tutti'}
              id="status"
              label="Stato evento"
              variant="outlined"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );


  if (user === undefined || reservationRequests === undefined) return <Loader />;


  return reservationRequests.length > 0 ? (
    <div className="reservation-requests-wrapper">
      {user.data.type === 'supplier' ? (
        <>
          <h4>Richieste</h4>
          <p className="description">Le richieste che hai ricevuto e che devi accettare o rifiutare</p>
        </>
      ) : (
        <>
          <h4>Richieste</h4>
          <p className="description">Le richieste che hai effettuato e che ancora non sono state confermate</p>
        </>
      )}

      {renderFilters()}

      <h5>Richieste</h5>
      {(filteredReservationRequests !== undefined
        ? filteredReservationRequests
        : reservationRequests).map((reservationRequest) => (
          <ReservationRequestRow
            reservationRequest={reservationRequest}
            onClick={() => setSelectedReservationRequest(reservationRequest)}
          />
      ))}

      <ReservationRequestDetail
        onClose={() => {
          setSelectedReservationRequest(undefined);
        }}
        onUpdate={() => {
          setSelectedReservationRequest(undefined);
        }}
        visible={showReservationRequestDetail}
        reservationRequest={selectedReservationRequest}
      />
    </div>
  ) : (
    <div className="reservation-requests-wrapper">
      <h4>Richieste</h4>
      {user.data.type === 'supplier' ? (
        <p>Non hai ancora ricevuto nessuna richiesta</p>
      ) : (
        <p>Non hai ancora mandato nessuna richiesta</p>
      )}
    </div>
  );
};
export default withRouter(ReservationRequests);
