import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './styles.css';
import { Container } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import { getMediaUrl, getFullAddress } from '../../libs/utils';
import CustomLoader from '../../components/loader/Loader';
import Review from '../../components/review/Review';
import ServiceItem from '../../components/service-item';
import SupplierMap from '../../components/supplier-map';
import SupplierCalendar from '../../components/supplier-calendar';
import ReservationStepForm from '../../components/reservation-step-form';


export default ({
  supplier, fetchSupplier, savedSearch,
}) => {
  const [showForm, setShowForm] = useState(false);
  const { supplierId } = useParams();


  useEffect(() => {
    fetchSupplier(supplierId);

    if (savedSearch) {
      setShowForm(true);
    }
  }, []);

  if (supplier === undefined) {
    return (
      <CustomLoader />
    );
  }

  
  const returnNameOfSupplier = (supplier) =>{
    return supplier.first_name + " " + supplier.last_name;
   }

  return (
    <div className="supplier-wrapper">
      <div className="supplier-info-top-wrapper">
        <div className="supplier-cover" style={{ backgroundImage: `url(${getMediaUrl(supplier.media.cover)})` }} />
        <div className="supplier-image-wrapper">
          <Container>
            <img className="supplier-image" src={getMediaUrl(supplier.media.profile)} alt="supplier cover" />
          </Container>
        </div>
        <Container>
          <div className="supplier-text-info-wrapper">
            <h2>{supplier.info.rag_soc} di {returnNameOfSupplier(supplier)}</h2>
            <p className="supplier-level">{supplier.level}</p>
            <p className="description">{supplier.info.description}</p>
          </div>
        </Container>


      </div>

      <Container className="supplier-content-wrapper">
        <Grid container>
          <Grid className="supplier-left" item xs={12} md={5}>
            {supplier.reviews.length > 0 && (
              <>
                <h4>Descrizione</h4>
                <p>{supplier.info.description}</p>
              </>
            )}

            {supplier.services.length > 0 && (
              <>
                <h4>Servizi</h4>
                {supplier.services.map((service) => (
                  <ServiceItem key={service.id} service={service} />
                ))}
              </>
            )}

            {supplier.info.description && (
              <>
                <h4>Recensioni</h4>
                {supplier.reviews && supplier.reviews.length > 0
                  ? supplier.reviews.map((review) => (
                    <Review key={`${review.score}_${review.done_by.id}_${review.text}`} review={review} />
                  )) : <p>Questo fornitore non ha ancora ricevuto nessuna recensione</p>}
              </>
            )}
          </Grid>
          <Grid className="supplier-right" item xs={12} md={7}>
            {supplier.addresses.length && false > 0 && (
              <>
                <h4>Posizione</h4>
                <SupplierMap
                  lat={supplier.addresses[0].geometries.latitude}
                  lng={supplier.addresses[0].geometries.longitude}
                />
                <p className="supplier-address-text">{getFullAddress(supplier.addresses[0])}</p>
              </>
            )}

            <h4>Prenotazione</h4>
            <SupplierCalendar onConfirm={() => {
              setShowForm(true);
            }}
            />

          </Grid>
        </Grid>


      </Container>

      <ReservationStepForm visible={showForm} onClose={() => setShowForm(false)} />

    </div>
  );
};
