import React from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import './styles.css';

const HowItWorks = () => (
  <div className="how-it-works-wrapper">
    <Container className="how-it-works">
      <Container container style={{ marginTop: 30, marginBottom: 60 }}>
        <h2>COME FUNZIONA</h2>
        <p style={{ marginTop: 20 }}>
          L’applicazione permette di prenotare i
          migliori servizi di fiducia a domicilio, con pochi passaggi e
          di avere nella propria area riservata un calendario dove poter gestire
           e monitorare lo stato di tutte le prestazioni.
        </p>
      </Container>
      <Container container style={{ marginTop: 30, marginBottom: 30 }}>
        <h3>CI SONO TRE TIPI DI UTENZE</h3>
        <h4 style={{ marginTop: 20 }}>ANZIANO</h4>
        <p>
        L&apos;
          <b>anziano</b>
          , colui che ha bisogno del servizio a domicilio, per prenotare deve:
          <ul>
            <li>selezionare la data in cui ha bisogno della prestazione a domicilio</li>
            <li>definire lo slot orario in cui ha bisogno della prestazione</li>
            <li>scegliere l’indirizzo dove ricevere la prestazione a domicilio</li>
            <li>
              scegliere in base al prezzo o alla vicinanza e alle recensioni tra i
              fornitori disponibili
            </li>
            <li>inserire una carta di credito valida a garanzia della prenotazione.</li>
          </ul>
          Il pagamento si perfeziona solo nel momento che colui che riceve la prestazione
           firma accetta prestazione e conclude la procedura nell’area riservata.
        </p>
        <h4 style={{ marginTop: 20 }}>FAMILIARE</h4>
        <p>
        Il
          {' '}
          <b>familiare</b>
          , colui che si occupa della gestione degli anziani, per prenotare deve:
          <ul>
            <li>Inserire gli anziani che hanno bisogno della prestazione a domicilio</li>
            <li>
              selezionare la data in cui l&apos;anziano ha bisogno della prestazione a domicilio
            </li>
            <li>definire lo slot orario in cui l&apos;anziano ha bisogno della prestazione</li>
            <li>
              scegliere l’indirizzo dove il l&apos;anziano vuol ricevere la prestazione
               a domicilio
            </li>
            <li>inserire una carta di credito valida a garanzia della prenotazione.</li>
          </ul>
        </p>
        <h4 style={{ marginTop: 20 }}>FORNITORE</h4>
        <p>
          Il
          {' '}
          <b>fornitore di servizi</b>
, colui che fornisce la prestazione a domicilio deve:

          <ul>
            <li>richiedere, registrandosi, di diventare un fornitore di servizi a domicilio</li>
            <li>definire il raggio di Km entro cui fornire la prestazione</li>
            <li>inserire la propria disponibilità giornaliera e oraria.</li>
            <li>inserire la durata della prestazione</li>
            <li>inserire il costo della prestazione</li>
            <li>chiudere la procedura una volta erogata la prestazione</li>
          </ul>
        </p>
      </Container>
    </Container>
  </div>
);

export default withRouter(HowItWorks);
