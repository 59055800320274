/* eslint-disable import/prefer-default-export */
import Swal from 'sweetalert2';
import {
  Api,
  SERVICES_FETCH,
  SERVICES_CATEGORIES_FETCH,
  CHANGE_RESERVATION_REQUEST_STATUS,
  CHANGE_RESERVATION_STATUS, fetchUserReservations, SUPPLIER_SEND_DOCUMENTS, fetchUserSelf,
} from '../actions';

const api = new Api();

export const servicesMiddleware = (store) => (next) => (action) => {
  const middlewareAction = {};

  middlewareAction[SERVICES_FETCH] = async () => {
    store.dispatch(await api.call({
      url: '/services?noPaginate',
      actionType: action.type,
    }));
  };

  middlewareAction[SERVICES_CATEGORIES_FETCH] = async () => {
    store.dispatch(await api.call({
      url: '/service-categories?noPaginate',
      actionType: action.type,
    }));
  };

  middlewareAction[CHANGE_RESERVATION_REQUEST_STATUS] = async () => {
    store.dispatch(await api.call({
      url: `/reservation-requests/${action.payload.reservationRequestId}/status`,
      method: 'post',
      data: action.payload,
      actionType: action.type,
    }));
  };

  middlewareAction[CHANGE_RESERVATION_STATUS] = async () => {
    const respAction = await api.call({
      url: `/reservations/${action.payload.reservationId}/status`,
      method: 'post',
      data: action.payload,
      actionType: action.type,
    });

    if (respAction.type === `${CHANGE_RESERVATION_STATUS}_SUCCESS` && action.onSuccess !== undefined) {
      action.onSuccess();
    } else if (action.onError) {
      action.onError();
    }

    store.dispatch(respAction);
  };

  middlewareAction[`${CHANGE_RESERVATION_STATUS}_SUCCESS`] = async () => {
    store.dispatch(fetchUserReservations());
    Swal.fire('Ok!', 'Operazione eseguita con successo.', 'success');
  };

  middlewareAction[`${CHANGE_RESERVATION_STATUS}_FAIL`] = async () => {
    Swal.fire('Errore', 'Errore durante la chiusura della prestazione', 'error');
  };

  next(action);

  if (middlewareAction[action.type]) { middlewareAction[action.type](); }
};
