/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';

import Loader from '../../../../components/loader';
import AddServiceModal from '../../../../components/add-service-modal';

import colors from '../../../../res/colors';

const SupplierServices = ({ user }) => {
  const [services, setServices] = useState();
  const [showModal, setShowModal] = useState(false);
  const [service, setService] = useState();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (
      user !== undefined
      || user.data !== undefined
      || user.data.supplier_data !== undefined
    ) {
      user.data.supplier_data.services.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      setServices(user.data.supplier_data.services);
    } else {
      setServices([]);
    }
  }, [user]);

  useEffect(() => {
    if (user.data.type === 'supplier' && services && services.length >= 1) setDisabled(true);
    else setDisabled(false);
  }, [services]);

  useEffect(() => {
    if (!showModal) {
      setService(undefined);
    }
  }, [setShowModal]);

  const renderModal = () => (
    <AddServiceModal
      visible={showModal}
      service={service}
      onClose={() => setShowModal(false)}
    />
  );

  if (user === undefined || services === undefined) return <Loader />;

  return (
    <div className="settings-content-wrapper account-settings-wrapper">
      {services.length > 0 ? services.map(
        (s) => (
          <div>
            <div className="setting-item-value">{s.name}</div>
            <div className="setting-item-edit">
              <p
                onClick={() => {
                  setService(s);
                  setShowModal(true);
                }}
              >
                Modifica
                <EditIcon style={{ fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
              </p>
            </div>
          </div>
        ),
      ) : <p>Non hai ancora aggiunto nessun servizio</p>}

      <AddCircleIcon
        onClick={() => {
          if (!disabled) {
            setService(undefined);
            setShowModal(true);
          }
        }}
        style={{
          color: disabled
            ? colors.grey.normal
            : colors.secondary.normal,
          fontSize: 40,
          marginTop: 30,
        }}
      >
        filled
      </AddCircleIcon>

      {disabled ? <p style={{ marginTop: 10 }}><small>Attualmente è possibile aggiungere un solo servizio per fornitore</small></p> : ''}

      {showModal && renderModal()}
    </div>
  );
};

export default SupplierServices;
