import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PasswordResetForm from './PasswordResetForm';

import { resetUserPassword } from '../../../store/actions';

const mapStateToProps = () => ({});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetUserPassword,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetForm);
