import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ConfirmEmail from './ConfirmEmail';

const mapStateToProps = (state) => ({
  registrationData: state.registrationData,
  user: state.user,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmEmail);
