import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';

import './styles.css';

import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getFullAddress, translateStatus, formatEventDatetime } from '../../libs/utils';

import SupplierMap from '../supplier-map';
import colors from '../../res/colors';

const ReservationDetail = ({
  visible, onClose, user, reservation, userCancelReservation, onUpdate,
  downloadInvoice, onReservationCloseIntent, changeReservationStatus,
}) => {
  if (reservation === undefined) return null;

  const { reservation_request: reservationRequest } = reservation;
  const {
    done_by: doneBy, service, end_date_time, address, notes, needy, supplier,
  } = reservationRequest;
  const endDate = moment(end_date_time);
  const { contacts: supplierContacts } = supplier;

  const startReservationHelper = () => {
    const hours = moment(`${reservation.reservation_request.date} ${reservation.reservation_request.timeslots[0].start}`, 'YYYY-MM-DD hh:mm').diff(moment()) / (1000 * 60 * 60);

    if ((hours < 3 && hours >= 0) || true) {
      changeReservationStatus({
        status: 'in-progress',
        message: '',
        reservationId: reservation.id,
      }, () => {
        if (onClose) {
          onClose();
        }
      });
    } else if (hours < 0) {
      Swal.fire({
        title: 'Non è possibile iniziare questa prestazione.',
        icon: 'error',
        confirmButtonColor: colors.red.normal,
        confirmButtonText: 'Ok',
      });
    } else {
      Swal.fire({
        title: 'Non è possibile iniziare adesso la prestazione.\nMancano più di 2 ore all\'inizio stabilito.',
        icon: 'error',
        confirmButtonColor: colors.red.normal,
        confirmButtonText: 'Ok',
      });
    }
  };

  return (
    <Modal show={visible} onHide={onClose} backdrop="static">
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        <h5 style={{ textAlign: 'center' }}>Riepilogo prestazione</h5>
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">
        <div className="reservation-recap-wrapper">
          <div className="reservation-recap-item">
            <h6>Stato</h6>
            <p>{`${translateStatus(reservation.status.value)}${reservation.status.message && reservation.status.message !== null ? `: ${reservation.status.message}` : ''}`}</p>
          </div>

          {user.data.type !== 'supplier' ? (
            <div className="reservation-recap-item">
              <h6>Codice di conferma conclusione della prestazione</h6>
              <p>{reservationRequest.token}</p>
            </div>
          ) : null}

          <div className="reservation-recap-item">
            <h6>Servizio richiesto da</h6>
            <p>{doneBy.full_name}</p>
          </div>

          {doneBy.type !== 'needy' && (
            <div className="reservation-recap-item">
              <h6>Da effettuare a</h6>
              <p>{needy.full_name}</p>
            </div>
          )}

          <div className="reservation-recap-item">
            <h6>Fornitore</h6>
            <p>{supplier.full_name}</p>
          </div>

          {supplierContacts.length > 0 && (
          <div className="reservation-recap-item contacts">
            <h6>Contatti</h6>
            <p>{supplierContacts.map((contact) => <span key={`key_${contact.value}`}>{contact.value}</span>)}</p>
          </div>
          )}

          <div className="reservation-recap-item">
            <h6>Servizio richiesto</h6>
            <p>{service.name}</p>
          </div>
          <div className="reservation-request-recap-item">
            <h6>Descrizione servizio richiesto</h6>
            <p>{service.description}</p>
          </div>
          <div className="reservation-recap-item">
            <h6>Quando</h6>
            <p>{formatEventDatetime(reservationRequest)}</p>
          </div>
          <div className="reservation-recap-item" style={{ marginBottom: 30 }}>
            <h6>Dove</h6>
            <p>{getFullAddress(address)}</p>
            {address.geometries && (
            <SupplierMap
              lat={address.geometries.latitude}
              lng={address.geometries.longitude}
            />
            )}
          </div>
          {notes && (
          <div className="reservation-recap-item">
            <h6>Note</h6>
            <p>{notes}</p>
          </div>
          )}
          <div className="reservation-recap-item" style={{ marginTop: 20 }}>
            <h6>Preventivo costi</h6>
            {`€ ${parseFloat(
              ((reservationRequest.price_at_request_time * reservationRequest.service.duration)
              / 6000) * reservationRequest.timeslots.length,
            ).toFixed(2)}`}
          </div>

          {reservation.status.value !== 'canceled' && reservation.status.value !== 'completed' && reservation.status.value !== 'in-progress' ? (
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 30 }}>
              <Button
                variant="red"
                onClick={() => {
                  Swal.fire({
                    title: 'Sei sicuro di voler cancellare questa prestazione?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: colors.secondary.normal,
                    cancelButtonColor: colors.red.normal,
                    confirmButtonText: 'Si, cancella!',
                    cancelButtonText: 'Annulla',
                  }).then((result) => {
                    if (result.value) {
                      userCancelReservation(reservation.id);
                      if (onUpdate) {
                        onUpdate();
                      }
                    }
                  });
                }}
              >
                {endDate.isBefore(moment) ? 'Cancella richiesta' : 'Annulla prestazione' /* TODO: check */}
              </Button>
            </div>
          ) : null}

          {reservation.status.value === 'booked' ? (
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 30 }}>
              <Button
                variant="green"
                onClick={() => {
                  Swal.fire({
                    title: 'Sei sicuro di voler iniziare questa prestazione?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: colors.secondary.normal,
                    cancelButtonColor: colors.red.normal,
                    confirmButtonText: 'Si, inizia',
                    cancelButtonText: 'Annulla',
                  }).then((result) => {
                    if (result.value) {
                      startReservationHelper();
                    }
                  });
                }}
              >
                Inizia prestazione
              </Button>
            </div>
          ) : null}

          {reservation.status.value === 'in-progress' ? (
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 30 }}>
              <Button
                variant="yellow"
                onClick={() => {
                  if (onReservationCloseIntent) {
                    onReservationCloseIntent();
                  }
                }}
              >
                <span style={{ color: 'white' }}>Concludi prestazione</span>
              </Button>
            </div>
          ) : null}

          {reservation.status.value === 'completed' ? (
            <Button
              style={{ marginLeft: 15, marginVertical: 10 }}
              variant="yellow"
              onClick={() => {
                downloadInvoice(reservationRequest.id);
              }}
            >
              Scarica ricevuta
            </Button>
          ) : null}

        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(ReservationDetail);
