import React, { useState, useEffect } from 'react';
import {
  Modal, Button as BootstrapButton,
} from 'react-bootstrap';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';


import './styles.css';

import Loader from '../loader';
import CustomInput from '../custom-input';


const LoginModal = ({
  visible, onClose, login, user, history,
}) => {
  const [sent, setSent] = useState(false);
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (visible) {
      setSent(false);
      setRedirected(false);
    }
  }, [visible]);

  useEffect(() => {
    if (user !== undefined && sent && !redirected) {
      history.push('/dashboard');
      setRedirected(true);
      onClose();
    }
  }, [user]);

  return (
    <Modal show={visible} onHide={onClose} backdrop="static">
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        <h5 style={{ textAlign: 'center' }}>Login</h5>
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">
        <Formik
          initialValues={{
            email: '', password: '', policy: false,
          }}
          validate={(values) => {
            const errors = {};

            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Indirizzo mail non valido';
            }
            return errors;
          }}
          onSubmit={(values) => {
            login({ username: values.email, password: values.password }, () => {
              setSent(true);
              // setTimeout(() => {
              //   history.push('/dashboard');
              //   onClose();
              // }, 1000);
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form style={{ display: 'block' }} onSubmit={handleSubmit} autoComplete="off">
              {sent ? (
                <Loader />
              ) : (
                <>
                  <div className="form-input-wrapper">
                    <CustomInput
                      required
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      value={values.email}
                      id="email"
                      label="Email"
                      variant="outlined"
                    />
                  </div>

                  <div className="form-input-wrapper">
                    <CustomInput
                      required
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                      value={values.password}
                      id="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                    />
                  </div>

                  <div className="errors-wrapper">
                    {errors.email && touched.email && errors.email}
                  </div>
                </>
              )}

              <div style={{ textAlign: 'center' }}>
                <BootstrapButton disabled={sent} type="submit" onClick={handleSubmit} variant="yellow">Login</BootstrapButton>
              </div>
            </form>
          )}
        </Formik>
        <div style={{ marginTop: 30 }}>
          <a style={{ textAlign: 'center' }} href="/password-reset"><h5>Recupero password</h5></a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(LoginModal);
