/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import { Formik } from 'formik';

import { getMediaUrl } from '../../../../libs/utils';

import Loader from '../../../../components/loader';
import CustomInput from '../../../../components/custom-input';

const SecuritySettings = ({ user, updateUserData, updateUserProfilePhoto }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleEdit = (key, type, value, label) => {
    const initValues = {};

    if (type === 'date') {
      initValues[key] = moment(value).format('DD/MM/YYYY');
    } else {
      initValues[key] = value;
    }

    setModalVisible(true);
    setModalContent(
      <div style={{ padding: 20 }}>
        <h6>
          Stai modificando:
          {' '}
          <span style={{ color: '#F98A1A' }}>{label}</span>
        </h6>
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
          }}
          validate={(values) => validate(values, type)}
          onSubmit={(values) => {
            if (type === 'date') {
              updateUserData(
                {
                  [key]: moment(values[key], 'DD/MM/YYYY').format('DD-MM-YYYY'),
                },
                () => setModalVisible(false)
              );
            } else if (type === 'media') {
              if (key === 'profile') {
                updateUserProfilePhoto(values[key], () =>
                  setModalVisible(false)
                );
              } else {
                console.warn('Unsupported media type');
              }
              // updateUserData({
              //   [key]: moment(values[key], 'DD/MM/YYYY').format('DD-MM-YYYY'),
              // }, () => setModalVisible(false));
            } else {
              updateUserData(values, () => setModalVisible(false));
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form
              style={{ marginTop: 30 }}
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              {(type === 'text' || type === 'email' || type === 'phone') && (
                <>
                  <div className="form-input-wrapper" style={{ padding: 0 }}>
                    <CustomInput
                      required
                      onChange={handleChange('oldPassword')}
                      onBlur={handleBlur('oldPassword')}
                      value={values['oldPassword']}
                      id={'oldPassword'}
                      variant="outlined"
                      placeholder="Vecchia password *"
                      error={touched.oldPassword && errors.oldPassword}
                      type="password"
                    />
                  </div>

                  <div
                    className="form-input-wrapper"
                    style={{ padding: 0, marginTop: 10 }}
                  >
                    <CustomInput
                      required
                      onChange={handleChange('newPassword')}
                      onBlur={handleBlur('newPassword')}
                      value={values['newPassword']}
                      id={'newPassword'}
                      variant="outlined"
                      placeholder="Nuova password *"
                      error={touched.newPassword && errors.newPassword}
                      type="password"
                    />
                  </div>

                  <div
                    className="form-input-wrapper"
                    style={{ padding: 0, marginTop: 10 }}
                  >
                    <CustomInput
                      required
                      onChange={handleChange('newPasswordConfirm')}
                      onBlur={handleBlur('newPasswordConfirm')}
                      value={values['newPasswordConfirm']}
                      id={'newPasswordConfirm'}
                      variant="outlined"
                      placeholder="Conferma nuova password *"
                      error={touched.newPasswordConfirm && errors.newPasswordConfirm}
                      type="password"
                    />
                  </div>
                </>
              )}

              <div className="errors-wrapper">
                {errors.email && touched.email && errors.email}
                {errors.privacy}
              </div>

              <div style={{ textAlign: 'center', marginBottom: 20 }}>
                <Button
                  style={{ marginRight: 15 }}
                  onClick={() => setModalVisible(false)}
                  variant="red"
                >
                  Annulla
                </Button>
                <Button
                  style={{ marginLeft: 15 }}
                  type="submit"
                  onClick={handleSubmit}
                  variant="yellow"
                >
                  Continua
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  };

  if (user === undefined || user.data === undefined) return <Loader />;

  return (
    <div className="settings-content-wrapper account-settings-wrapper">
      <div>
        <div className="setting-item-title">Password</div>
        <div className="setting-item-value">********</div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('password', 'text', '', 'Password')}>
            Modifica
            <EditIcon style={{ color: '#F98A1A', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        backdrop="static"
      >
        {modalContent}
      </Modal>
    </div>
  );
};

const validate = (values, type) => {
  const errors = {};
  if (type === 'email') {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Indirizzo mail non valido';
    }
  }

  if (values.oldPassword === undefined) errors.oldPassword = 'Campo obbligatorio';
  if (values.newPassword === undefined) errors.newPassword = 'Campo obbligatorio';
  if (values.newPasswordConfirm === undefined) errors.newPasswordConfirm = 'Campo obbligatorio';
  if (values.newPasswordConfirm !== values.newPassword) errors.newPasswordConfirm = 'Le due password non corrispondono';

  return errors;
};

export default SecuritySettings;
