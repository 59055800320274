/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import images from '../../../res/images';
import './style.css';


const RegistrationChoice = ({
  history, user, search,
}) => {
  useEffect(() => {
    if (user) {
      history.push('/dashboard');
    }
  }, [user]);

  return (
    <div className="registration-choice-item-wrapper">
      <ChoiseItem
        onClick={() => history.push('/registrazione/anziano')}
        image={images.needy}
        title="anziano"
        description="Cerca tra i nostri servizi quello che fa per te e prenota direttamente tu stesso"
      />
      <ChoiseItem
        onClick={() => history.push('/registrazione/familiare')}

        image={images.carer}
        title="familiare"
        description="Prenditi cura dei tuoi cari, prenotando i servizi di cui hanno bisogno al posto loro"
      />

      {search ? null : (
        <ChoiseItem
          onClick={() => history.push('/registrazione/fornitore')}
          image={images.professional}
          title="professionista"
          description="Offri i tuoi servizi professionali agli utenti di My Seren Care"
        />
      )}

    </div>
  );
};

const ChoiseItem = ({
  image, title, description, onClick,
}) => (
  <div
    onClick={onClick}
    className="registration-choice-item"
    style={{ backgroundImage: `url(${image})` }}
  >
    <h4>
      Iscriviti come utente
      {' '}
      <span>{title}</span>
    </h4>
    <p>{description}</p>
  </div>
);

export default withRouter(RegistrationChoice);
