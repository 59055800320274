import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from '@material-ui/core';
import moment from 'moment';


import Loader from '../../../../components/loader';
import CustomInput from '../../../../components/custom-input';
import './styles.css';
import { parseSupplierTimeslots, parseDayOfTimetable } from '../../../../libs/utils';

const Availability = ({ user, loading, updateTimetable }) => {
  const [days, setDays] = useState(new Array(7).fill(false));
  const [times, setTimes] = useState(new Array(7));

  useEffect(() => {
    const timetable = parseSupplierTimeslots(user.data.info.timeslots);
    const d = timetable.map((item) => {
      if (item.morning.start === '' && item.morning.end === '' && item.afternoon.start === '' && item.afternoon.end === '') {
        return false;
      }
      return true;
    });

    setDays(d);
    setTimes(timetable);
  }, []);


  const setTimesHelper = (value, index, isMorning, isStart) => {
    const t = [...times];
    t[index][isMorning ? 'morning' : 'afternoon'][isStart ? 'start' : 'end'] = value;
    setTimes(t);
  };

  const validateTimeslot = (time, timeSet, part, type) => {
    if (time.length === 0) return undefined;

    if (time !== undefined && !RegExp('^([0-1][0-9]|[2][0-3]):([0-5][0-9])$').test(time)) {
      return 'Formato non valido, utilizzare HH:MM';
    }

    if (part === 'morning') {
      if (type === 'start') {
        const [startTimeHour, startTimeMinute] = time.split(':');
        if (timeSet[part].end === undefined || timeSet[part].end.length === 0) return 'Orario incompleto';
        const [endTimeHour, endTimeMinute] = timeSet[part].end.split(':');

        const startTime = moment().set({
          hour: startTimeHour, minute: startTimeMinute, second: 0, millisecond: 0,
        });

        const endTime = moment().set({
          hour: endTimeHour, minute: endTimeMinute, second: 0, millisecond: 0,
        });

        if (startTime.isAfter(endTime)) return 'Orario di apertura superiore all\'orario di chiusura';
      }

      if (type === 'end') {
        const [startTimeHour, startTimeMinute] = timeSet[part].start.split(':');
        if (timeSet[part].end === undefined || timeSet[part].end.length === 0) return 'Orario incompleto';
        const [endTimeHour, endTimeMinute] = time.split(':');

        const startTime = moment().set({
          hour: startTimeHour, minute: startTimeMinute, second: 0, millisecond: 0,
        });

        const endTime = moment().set({
          hour: endTimeHour, minute: endTimeMinute, second: 0, millisecond: 0,
        });

        if (startTime.isAfter(endTime)) return 'Orario di chiusura inferiore all\'orario di apertura';
      }
    }

    if (part === 'afternoon') {
      if (type === 'start') {
        const [startTimeHour, startTimeMinute] = time.split(':');
        if (timeSet[part].end === undefined || timeSet[part].end.length === 0) return 'Orario incompleto';
        const [endTimeHour, endTimeMinute] = timeSet[part].end.split(':');
        const [morningEndTimeHour, morningEndTimeMinute] = timeSet.morning.end.split(':');

        const startTime = moment().set({
          hour: startTimeHour, minute: startTimeMinute, second: 0, millisecond: 0,
        });

        const endTime = moment().set({
          hour: endTimeHour, minute: endTimeMinute, second: 0, millisecond: 0,
        });

        const morningEndTime = moment().set({
          hour: morningEndTimeHour, minute: morningEndTimeMinute, second: 0, millisecond: 0,
        });

        if (startTime.isAfter(endTime)) return 'Orario di apertura superiore all\'orario di chiusura';
        if (startTime.isBefore(morningEndTime)) return 'Orario di apertura inferiore all\'orario di chiusura mattutino';
      }

      if (type === 'end') {
        const [startTimeHour, startTimeMinute] = timeSet[part].start.split(':');
        if (timeSet[part].end === undefined || timeSet[part].end.length === 0) return 'Orario incompleto';
        const [endTimeHour, endTimeMinute] = time.split(':');

        const startTime = moment().set({
          hour: startTimeHour, minute: startTimeMinute, second: 0, millisecond: 0,
        });

        const endTime = moment().set({
          hour: endTimeHour, minute: endTimeMinute, second: 0, millisecond: 0,
        });

        if (startTime.isAfter(endTime)) return 'Orario di chiusura inferiore all\'orario di apertura';
      }
    }

    return undefined;
  };

  const submitHelper = () => {
    const d = [...days]
      .map((item, index) => ({ index, selected: item }))
      .filter((item) => item.selected)
      .map((item) => parseDayOfTimetable(item.index, times[item.index]));

    const obj = {};

    d.forEach((item) => {
      const key = Object.keys(item)[0];
      obj[key] = item[key];
    });

    updateTimetable(obj);
  };

  const isConfirmButtonDisabled = () => {
    if (days.filter((item) => item).length === 0) {
      return true;
    }

    const check = days
      .map((item, index) => ({ value: item, index }))
      .filter((item) => item.value)
      .map((item) => {
        const morningStartCheck = validateTimeslot(times[item.index].morning.start, times[item.index], 'morning', 'start');
        const morningEndCheck = validateTimeslot(times[item.index].morning.end, times[item.index], 'morning', 'end');
        const afternoonStartCheck = validateTimeslot(times[item.index].afternoon.start, times[item.index], 'afternoon', 'start');
        const afternoonEndCheck = validateTimeslot(times[item.index].afternoon.end, times[item.index], 'afternoon', 'end');
        return morningStartCheck === undefined
        && morningEndCheck === undefined
        && afternoonStartCheck === undefined && afternoonEndCheck === undefined;
      });

    return !check.reduce((tot, c) => tot && c);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ marginTop: 40 }}>
          <p>Seleziona i giorni della settimana in cui sei disponibile</p>
          <div>
            {days.map((item, index) => (
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={days[index]}
                    onChange={() => {
                      const d = [...days];
                      d[index] = !d[index];
                      setDays(d);
                    }}
                    value="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
              )}
                label={daysNamesMap[index]}
              />
            ))}
          </div>

          {days.filter((item) => item).length > 0 && (
            <>
              <p style={{ marginTop: 30 }}>
                Seleziona l&apos;orario per ogni giorno in cui sei disponibile
              </p>

              {days.map((item, index) => ({
                index, selected: item,
              })).filter((item) => item.selected).map((item) => (
                <>
                  <h5>{daysNamesMap[item.index]}</h5>
                  <Grid container>
                    <Grid xs={12} md={6} style={{ paddingRight: 30 }}>
                      <h6>Mattina</h6>
                      <div style={{ display: 'table' }}>
                        <div style={{ display: 'table-cell', verticalAlign: 'top', paddingRight: 2 }}>
                          <CustomInput
                            type="text"
                            required
                            onChange={(event) => {
                              setTimesHelper(event.target.value, item.index, true, true);
                            }}
                            onBlur={() => {}}
                            value={times[item.index].morning.start}
                            variant="outlined"
                            error={validateTimeslot(times[item.index].morning.start, times[item.index], 'morning', 'start')}
                          />
                        </div>

                        <div style={{ display: 'table-cell', verticalAlign: 'top', paddingLeft: 2 }}>
                          <CustomInput
                            type="text"
                            required
                            onChange={(event) => {
                              setTimesHelper(event.target.value, item.index, true, false);
                            }}
                            value={times[item.index].morning.end}
                            variant="outlined"
                            error={validateTimeslot(times[item.index].morning.end, times[item.index], 'morning', 'end')}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <h6>Pomeriggio</h6>
                      <div style={{ display: 'table' }}>
                        <div style={{ display: 'table-cell', verticalAlign: 'top', paddingRight: 2 }}>
                          <CustomInput
                            type="text"
                            required
                            onChange={(event) => {
                              setTimesHelper(event.target.value, item.index, false, true);
                            }}
                            value={times[item.index].afternoon.start}
                            variant="outlined"
                            error={validateTimeslot(times[item.index].afternoon.start, times[item.index], 'afternoon', 'start')}
                          />
                        </div>

                        <div style={{ display: 'table-cell', verticalAlign: 'top', paddingLeft: 2 }}>
                          <CustomInput
                            type="text"
                            required
                            onChange={(event) => {
                              setTimesHelper(event.target.value, item.index, false, false);
                            }}
                            value={times[item.index].afternoon.end}
                            variant="outlined"
                            error={validateTimeslot(times[item.index].afternoon.end, times[item.index], 'afternoon', 'end')}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </>
              ))}


            </>

          )}


          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <Button
              style={{ marginLeft: 15 }}
              variant="yellow"
              disabled={isConfirmButtonDisabled()}
              onClick={submitHelper}
            >
        Salva
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Availability;

const daysNamesMap = {
  0: 'Lunedì',
  1: 'Martedì',
  2: 'Mercoledì',
  3: 'Giovedì',
  4: 'Venerdì',
  5: 'Sabato',
  6: 'Domenica',
};
