import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField } from '@material-ui/core';

import './styles.css';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import colors from '../../res/colors';
import Loader from '../loader';

const SearchOverviewBar = ({
  categories, services, search, performSearch, addSearchOption, onOrderChange, initialOrder,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedService, setSelectedService] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedOrder, setSelectedOrder] = useState(initialOrder);

  useEffect(() => {
    if (search !== undefined && categories !== undefined && services !== undefined) {
      setSelectedDate(moment(search.date));
      const service = services.find((item) => item.id === search.service_id);
      if (service) {
        setSelectedService(service);
        const category = categories.find((item) => item.id === service.category_id);
        if (category) {
          setSelectedCategory(category);
        }
      }
    }
  }, [categories, services]);

  useEffect(() => {
    onOrderChange(selectedOrder);
  }, [selectedOrder]);

  useEffect(() => {
    if (search) {
      performSearch();
    }
  }, [search]);

  useEffect(() => {
    if (search && selectedDate) {
      const dateStr = selectedDate.format('YYYY-MM-DD');
      if (search.date !== dateStr) {
        addSearchOption({
          key: 'date',
          data: dateStr,
        });
      }
    }
  }, [selectedDate, search]);


  if (services === undefined || categories === undefined || selectedCategory === undefined) {
    return (
      <Loader />
    );
  }

  return (
    <Box boxShadow={2} style={{ borderRadius: 4, margin: 30 }}>
      <div style={{ borderColor: colors.primary.normal }} className="search-overview-wrapper">
        <h6>Parametri ricerca</h6>

        <Grid container>

          <Grid item xs={12} md={4} className="search-overview-input-wrapper">
            <FormControl>
              <InputLabel>Categoria</InputLabel>
              <Select
                value={selectedCategory.id}
                onChange={(event) => {
                  setSelectedCategory(categories.find((c) => c.id === event.target.value));
                  setSelectedService(undefined);
                }}
              >
                {categories.map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} className="search-overview-input-wrapper">
            <FormControl>
              <InputLabel>Servizio</InputLabel>
              <Select
                value={selectedService && selectedService.id}
                onChange={(event) => {
                  const service = services.find((s) => s.id === event.target.value);
                  setSelectedService(service);
                  addSearchOption({
                    key: 'service_id',
                    data: service.id,
                  });
                }}
              >
                {services
                  .filter((s) => s.category_id === selectedCategory.id)
                  .map((s) => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} className="search-overview-input-wrapper">
            <FormControl>

              <TextField
                id="date"
                label="Data"
                type="date"
                defaultValue={selectedDate.format('YYYY-MM-DD')}
                onChange={(event) => setSelectedDate(moment(event.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} className="search-overview-input-wrapper">
            <FormControl>
              <InputLabel>Ordina per</InputLabel>
              <Select
                value={selectedOrder}
                onChange={(event) => {
                  setSelectedOrder(event.target.value);
                }}
              >
                <MenuItem value="price">Prezzo</MenuItem>
                <MenuItem value="reviews">Recensioni</MenuItem>
                <MenuItem value="distance">Distanza</MenuItem>
              </Select>
            </FormControl>
          </Grid>


        </Grid>

      </div>
    </Box>

  );
};

export default SearchOverviewBar;
