/* eslint-disable global-require */
export default {
  icons: {
    add: require('./icons/add.png'),
    star: require('./icons/star.png'),
    stargray: require('./icons/star-gray.png'),
    list: require('./icons/list.png'),
    search: require('./icons/search.png'),
    agenda: require('./icons/agenda.png'),
    phone: require('./icons/phone.png'),
    settings: require('./icons/settings.png'),
    arrows: {
      left: require('./icons/left-arrow.png'),
      right: require('./icons/right-arrow.png'),
    },
  },
  logo: require('./logo.png'),
  carer: require('./couple.png'),
  needy: require('./parents.png'),
  professional: require('./secretary.png'),
  elder: require('./elder.png'),
  calendar: require('./appointment.png'),
  request: require('./requests.png'),
  my_needy: require('./resume.png'),
};
