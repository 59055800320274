import React from 'react';
import TextEllipsis from 'react-text-ellipsis';
import { Grid, Box } from '@material-ui/core';


import './style.css';
import { getMediaUrl, parseTotalPrice, formatEventDatetime } from '../../libs/utils';

const ReservationRequestRow = ({ reservationRequest, onClick, user }) => {
  const whenString = formatEventDatetime(reservationRequest);

  const profileImage = user.data && user.data.type === 'supplier'
    ? getMediaUrl(reservationRequest.needy.media.profile)
    : getMediaUrl(reservationRequest.supplier.media.profile);


  const userFullName = user.data && user.data.type === 'supplier'
    ? `${reservationRequest.needy.first_name} ${reservationRequest.needy.last_name}`
    : reservationRequest.supplier.full_name;

  return (
    <Grid onClick={onClick} key={reservationRequest.id} className={`reservation-request-row-wrapper ${reservationRequest.status.value}`} item xs={12}>
      <Box boxShadow={3} className="reservation-request-row">
        <div className="supplier-wrapper">
          <img src={profileImage} alt="Supplier" />
          <div className="supplier-texts-wrapper">
            <TextEllipsis
              lines={1}
              tag="h6"
              ellipsisChars="..."
              tagClass="supplier-title"
              debounceTimeoutOnResize={200}
              useJsOnly
            >
              {userFullName}
            </TextEllipsis>
          </div>
          <div className="yellow-spacer" />
        </div>
        <div className="reservation-request-info">
          <div>
            <div className="reservation-request-service">
              <span className="supplier-info-label">Servizio richiesto</span>
              <span className="supplier-info-value">{reservationRequest.service.name}</span>
            </div>
            <div>
              <span className="supplier-info-label">Quando</span>
              <span className="supplier-info-value">{whenString}</span>
            </div>
            <div>
              <span className="supplier-info-label">Costo</span>
              <span className="supplier-info-value">{parseTotalPrice(reservationRequest.price_at_request_time, reservationRequest.service.duration, reservationRequest.timeslots.length)}</span>
            </div>
          </div>

          {reservationRequest.notes && (
          <div style={{ marginTop: 10 }}>
            <div>
              <span className="supplier-info-label">Note</span>
              <span className="supplier-info-value">{reservationRequest.notes}</span>
            </div>
          </div>
          )}

        </div>
      </Box>
    </Grid>
  );

  // return (
  //   <Grid key={reservationRequest.id} className="reservation-request-row-wrapper" item xs={12}>
  //     <Box boxShadow={3} className="reservation-request-row">
  //       <div className="search-top-bar">
  //         <img src={getMediaUrl(reservationRequest.media.profile)} alt="Search item" />
  //         <div className="name-title-wrapper">
  //           <h5 className="search-item-title">{`${item.first_name} ${item.last_name}`}</h5>
  //           <span className="level-text">{item.level}</span>
  //         </div>
  //         <div style={{
  //           marginLeft: 20, marginRight: 20,
  // width: 4, height: 40, backgroundColor: colors.secondary.normal,
  //         }}
  //         />
  //         {item.score && (
  //         <div className="other-info-wrapper">
  //           <span className="other-info-title">Media recensioni</span>
  //           <span className="other-info-value">{item.score}</span>
  //         </div>
  //         )}
  //         {item.avg_price && (
  //         <div className="other-info-wrapper">
  //           <span className="other-info-title">Prezzo medio</span>
  //           <span className="other-info-value">{`${(parseFloat(item.avg_price)
  // / 100).toFixed(2)}€` }</span>
  //         </div>
  //         )}
  //       </div>
  //     </Box>
  //   </Grid>
  // );
};

export default ReservationRequestRow;
