/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Formik } from 'formik';
import TextEllipsis from 'react-text-ellipsis';


import { getMediaUrl } from '../../../../libs/utils';

import Loader from '../../../../components/loader';
import CustomInput from '../../../../components/custom-input';

import './styles.css';
import ImageUploader from 'react-images-upload';

const AccountSettings = ({
  user, updateUserData, updateUserProfilePhoto, updateUserCoverPhoto,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleEdit = (key, type, value, label) => {
    const initValues = {};

    if (type === 'date') {
      initValues[key] = moment(value).format('DD/MM/YYYY');
    } else {
      initValues[key] = value;
    }

    const ToInputCodiceFiscaleUppercase = (e) => {
      let idInput = e.target.id;
      if (idInput && idInput.includes("cod_fisc_supplier")) {
        e.target.value = ("" + e.target.value).toUpperCase();
      }
    };

    setModalVisible(true);
    setModalContent(
      <div style={{ padding: 20 }}>
        <h6>
          Stai modificando:
          {' '}
          <span style={{ color: '#F98A1A' }}>{label}</span>
        </h6>
        <Formik
          initialValues={initValues}
          validate={(values) => validate(values, type)}
          onSubmit={(values) => {
            if (type === 'date') {
              updateUserData({
                [key]: moment(values[key], 'DD/MM/YYYY').format('DD-MM-YYYY'),
              }, () => setModalVisible(false));
            } else if (type === 'media') {
              if (key === 'profile') {
                updateUserProfilePhoto(values[key], () => setModalVisible(false));
              } else if (key === 'cover') {
                updateUserCoverPhoto(values[key], () => setModalVisible(false));
              }
              // updateUserData({
              //   [key]: moment(values[key], 'DD/MM/YYYY').format('DD-MM-YYYY'),
              // }, () => setModalVisible(false));
            } else {
              updateUserData(values, () => setModalVisible(false));
            }
          }}
        >
          {({
            values, errors, touched, handleChange, handleBlur, handleSubmit,
          }) => (
            <form style={{ marginTop: 30 }} onSubmit={handleSubmit} autoComplete="off">

              {(type === 'text' || type === 'email' || type === 'phone') && (
              <div className="form-input-wrapper" style={{ padding: 0 }}>
                <CustomInput
                  required
                  onChange={handleChange(key)}
                  onBlur={handleBlur(key)}
                  value={values[key]}
                  id={key}
                  onInput={ToInputCodiceFiscaleUppercase}
                  variant="outlined"
                />
              </div>
              )}


              {type === 'gender' && (
              <div
                className="dropdown-input-wrapper"
                style={{ marginLeft: 20, marginRight: 20 }}
              >
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <CustomInput
                      required
                      onChange={handleChange('gender')}
                      onBlur={handleBlur('gender')}
                      value={values.gender}
                      id="gender"
                      label="Sesso"
                      variant="outlined"
                      contentEditable={false}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onSelect={() => handleChange('gender')('Uomo')}>Uomo</Dropdown.Item>
                    <Dropdown.Item onSelect={() => handleChange('gender')('Donna')}>Donna</Dropdown.Item>
                    <Dropdown.Item onSelect={() => handleChange('gender')('Altro')}>Altro</Dropdown.Item>
                    <Dropdown.Item onSelect={() => handleChange('gender')('Preferisco non specificare')}>Preferisco non specificare</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              )}


              {type === 'date' && (
              <div
                className="datepicker-input-wrapper"
                style={{
                  marginTop: 20, marginBottom: 20, marginLeft: 20, marginRight: 20,
                }}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  dateFormat="dd/MM/Y"
                  showYearDropdown
                  selected={moment(values[key], 'DD/MM/YYYY')._d}
                  onChange={(date) => handleChange(key)(moment(date).format('DD/MM/YYYY'))}
                  customInput={(
                    <CustomInput
                      value={values[key]}
                      id="birthday"
                      label="Data di nascita"
                      variant="outlined"
                      contentEditable={false}
                    />
                  )}
                />
              </div>
              )}

              {type === 'media' && (
                  <ImageUploader
                  withIcon
                  buttonText="Seleziona file"
                  imgExtension={['.jpg', '.jpeg', '.png']}
                  maxFileSize={5242880}
                  fileSizeError="File troppo grande"
                  fileTypeError="Estensione del file non supportata"
                  label="Estensione accettate: jpg, jpeg. Max. 5MB"
                  singleImage
                  withPreview
                  onChange={(file) => {
                    values[key] = file[0];
                  }}
                />
              )}

              <div className="errors-wrapper">
                {errors.email && touched.email && errors.email}
                {errors.privacy}
              </div>

              <div style={{ textAlign: 'center', marginBottom: 20 }}>
                <Button style={{ marginRight: 15 }} onClick={() => setModalVisible(false)} variant="red">Annulla</Button>
                <Button style={{ marginLeft: 15 }} type="submit" onClick={handleSubmit} variant="yellow">Continua</Button>
              </div>
            </form>
          )}
        </Formik>
      </div>,
    );
  };

  if (user === undefined || user.data === undefined) return <Loader />;

  return (
    <div className="settings-content-wrapper account-settings-wrapper">
      <div>
        <div className="setting-item-title">Ragione sociale</div>
        <div className="setting-item-value">
          <TextEllipsis
            lines={2}
            tag="p"
            ellipsisChars="..."
            debounceTimeoutOnResize={200}
            useJsOnly
          >
            {user.data.info.rag_soc}
          </TextEllipsis>
        </div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('rag_soc', 'text', user.data.info.rag_soc, 'Ragione sociale')}>
            Modifica
            <EditIcon style={{ color: '#F98A1A', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Iban</div>
        <div className="setting-item-value">
          <TextEllipsis
            lines={2}
            tag="p"
            ellipsisChars="..."
            debounceTimeoutOnResize={200}
            useJsOnly
          >
            {user.data.info.iban}
          </TextEllipsis>
        </div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('iban', 'text', user.data.info.iban, 'Iban')}>
            Modifica
            <EditIcon style={{ color: '#F98A1A', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Partita iva</div>
        <div className="setting-item-value">
          <TextEllipsis
            lines={2}
            tag="p"
            ellipsisChars="..."
            debounceTimeoutOnResize={200}
            useJsOnly
          >
            {user.data.info.piva}
          </TextEllipsis>
        </div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('piva', 'text', user.data.info.piva, 'Partita IVA')}>
            Modifica
            <EditIcon style={{ color: '#F98A1A', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Codice fiscale</div>
        <div className="setting-item-value">
          <TextEllipsis
            lines={2}
            tag="p"
            ellipsisChars="..."
            debounceTimeoutOnResize={200}
            useJsOnly
          >
            {user.data.info.cod_fisc}
          </TextEllipsis>
        </div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('cod_fisc_supplier', 'text', user.data.info.cod_fisc, 'Codice fiscale')}>
            Modifica
            <EditIcon style={{ color: '#F98A1A', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Descrizione</div>
        <div className="setting-item-value">
          <TextEllipsis
            lines={4}
            tag="p"
            ellipsisChars="..."
            debounceTimeoutOnResize={200}
            useJsOnly
          >
            {user.data.supplier_data.info.description}
          </TextEllipsis>
        </div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('description', 'text', user.data.supplier_data.info.description, 'Descrizione')}>
            Modifica
            <EditIcon style={{ color: '#F98A1A', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Foto di copertina</div>
        <div className="setting-item-value">
          <img alt="profile" src={getMediaUrl(user.data.media.cover)} />
        </div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('cover', 'media', user.data.media.cover, 'Foto di copertina')}>
            Modifica
            <EditIcon style={{ color: '#F98A1A', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>


      <Modal show={modalVisible} onHide={() => setModalVisible(false)} backdrop="static">
        {modalContent}
      </Modal>

    </div>
  );
};

const validate = (values, type) => {
  const errors = {};

  if (type === 'email') {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Indirizzo mail non valido';
    }
  }

  return errors;
};

export default AccountSettings;
