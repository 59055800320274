import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import moment from 'moment/min/moment-with-locales';
import { withRouter } from 'react-router-dom';

import CustomInput from '../custom-input';
import colors from '../../res/colors';
import './styles.css';

const cd = new Date();
const years = [...Array(120).keys()].map((item, index) => cd.getFullYear() - index);
const months = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];


const AddNeedyModal = ({
  visible, onClose, addNeedy, needyToEdit, updateNeedy, genders, fetchGenders,
}) => {
  const [initialValues] = useState(needyToEdit ? {
    first_name: needyToEdit.first_name || '',
    last_name: needyToEdit.last_name || '',
    gender: needyToEdit.gender || '',
    email: needyToEdit.email || '',
    email_confirm: needyToEdit.email || '',
    phone_number: needyToEdit.phone_number || '',
    mobile_number: needyToEdit.mobile_number || '',
    birthday: moment(needyToEdit.birthday).format('DD/MM/YYYY'),
    privacy: true,
  } : {
    first_name: '',
    last_name: '',
    gender: '',
    email: '',
    phone_number: '',
    mobile_number: '',
    password: '',
    birthday: moment().subtract(60, 'year').format('DD/MM/YYYY'),
    privacy: false,
  });

  useEffect(() => {
    if (genders === undefined) {
      fetchGenders();
    }
  });

  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Il nome contiene pochi caratteri')
      .max(50, 'Il nome contiene troppi caratteri')
      .required('Campo obbligatorio'),
    last_name: Yup.string()
      .min(2, 'Il cognome contiene pochi caratteri')
      .max(50, 'Il cognome contiene troppi caratteri')
      .required('Campo obbligatorio'),
    email: Yup.string()
      .email('L\'email non è valida'),
    birthday: Yup.string()
      .required('Campo obbligatorio'),
    gender: Yup.string()
      .oneOf(genders ? genders.map((g) => g.name) : [], 'Sesso non valido')
      .required('Campo obbligatorio'),
    mobile_number: Yup.string()
      .min(9, 'Numero non valido')
      .max(14, 'Numero non valido'),
    phone_number: Yup.string()
      .min(9, 'Numero non valido')
      .max(14, 'Numero non valido'),
    email_confirm: Yup.string()
      .test('checkEmailConfirmation', 'Non è uguale alla email inserita', function (value) {
        const email = this.resolve(Yup.ref('email'));
        return email === value;
      }),
  });

  const renderForm = useCallback(() => (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        if (needyToEdit) {
          updateNeedy({
            ...values,
            id: needyToEdit.id,
            birthday: moment(values.birthday, 'DD/MM/YYYY').format('DD-MM-YYYY'),
          });
          onClose();
        } else {
          addNeedy({
            ...values,
            birthday: moment(values.birthday, 'DD/MM/YYYY').format('DD-MM-YYYY'),
          });
          onClose();
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form style={{ marginTop: 30 }} onSubmit={handleSubmit} autoComplete="off">

          <Grid container>
            <Grid item xs={12}>
              <div className="form-input-wrapper">
                <CustomInput
                  type="text"
                  required
                  onChange={handleChange('first_name')}
                  onBlur={handleBlur('first_name')}
                  value={values.first_name}
                  id="first_name"
                  label="Nome"
                  variant="outlined"
                  error={touched.first_name && errors.first_name}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-input-wrapper">
                <CustomInput
                  type="text"
                  required
                  onChange={handleChange('last_name')}
                  onBlur={handleBlur('last_name')}
                  value={values.last_name}
                  id="last_name"
                  label="Cognome"
                  variant="outlined"
                  error={touched.last_name && errors.last_name}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-input-wrapper">
                <CustomInput
                  type="select"
                  title="Seleziona il tuo sesso"
                  options={genders && genders.map((g) => g.name)}
                  required
                  onChange={handleChange('gender')}
                  onBlur={handleBlur('gender')}
                  value={values.gender}
                  id="gender"
                  label="Sesso"
                  variant="outlined"
                  error={touched.gender && errors.gender}
                />
              </div>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: 20 }}>
              <div className="datepicker-input-wrapper" style={{ marginLeft: 20, marginRight: 20 }}>
                <DatePicker
                  style={{ width: '100%' }}
                  dateFormat="dd/MM/Y"
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <button
                        style={{
                          border: 0, color: colors.secondary.normal, fontWeight: 'bold', marginRight: 10,
                        }}
                        type="button"
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {'<'}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        style={{
                          border: 0, color: colors.secondary.normal, fontWeight: 'bold', marginLeft: 10,
                        }}
                        type="button"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {'>'}
                      </button>
                    </div>
                  )}
                  selected={moment(values.birthday, 'DD/MM/YYYY')._d}
                  onChange={(date) => handleChange('birthday')(moment(date).format('DD/MM/YYYY'))}
                  customInput={(
                    <CustomInput
                      value={values.birthday}
                      id="birthday"
                      label="Data di nascita"
                      variant="outlined"
                      contentEditable={false}
                      error={touched.birthday && errors.birthday}
                    />
                      )}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-input-wrapper">
                <CustomInput
                  onChange={handleChange('phone_number')}
                  onBlur={handleBlur('phone_number')}
                  value={values.phone_number}
                  id="phone_number"
                  label="Telefono fisso"
                  variant="outlined"
                  error={touched.phone_number && errors.phone_number}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-input-wrapper">
                <CustomInput
                  onChange={handleChange('mobile_number')}
                  onBlur={handleBlur('mobile_number')}
                  value={values.mobile_number}
                  id="mobile_number"
                  label="Telefono cellulare"
                  variant="outlined"
                  error={touched.mobile_number && errors.mobile_number}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-input-wrapper">
                <CustomInput
                  disabled={needyToEdit !== undefined}
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  id="email"
                  label="Email"
                  variant="outlined"
                  error={touched.email && errors.email}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-input-wrapper">
                <CustomInput
                  disabled={needyToEdit !== undefined}
                  onChange={handleChange('email_confirm')}
                  onBlur={handleBlur('email_confirm')}
                  value={values.email_confirm}
                  error={touched.email_confirm && errors.email_confirm}
                  id="email_confirm"
                  label="Conferma email"
                  variant="outlined"
                />
              </div>
            </Grid>

          </Grid>

          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            <Button style={{ marginRight: 15 }} onClick={onClose} variant="yellow">Annulla</Button>
            <Button style={{ marginLeft: 15 }} type="submit" onClick={handleSubmit} disabled={Object.keys(errors).length > 0} variant="green">Invia</Button>
          </div>
        </form>
      )}
    </Formik>
  ), [initialValues, genders]);

  return (
    <Modal show={visible} onHide={onClose} backdrop="static">
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        {needyToEdit ? (
          <h5>Modifica anziano</h5>
        ) : (
          <h5>Aggiungi anziano</h5>
        )}
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">
        {renderForm()}
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(AddNeedyModal);
