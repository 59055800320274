import React, { useCallback, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Box, useMediaQuery } from '@material-ui/core';
import DatePicker from 'react-datepicker';

import './style.css';
import moment from 'moment/min/moment-with-locales';
import { useLocation } from 'react-router-dom';
import CustomDropdown from '../custom-dropdown/CustomDropdown';
import CustomLoader from '../loader/Loader';

moment.locale('it');

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SupplierCalendar = ({
  supplier, search, timeslots, fetchTimeSlots, onConfirm, addSearchOption,
}) => {
  const [selectedDate, setSelectedDate] = useState(moment(search ? search.date : undefined));
  const [selectedService, setSelectedService] = useState(search
    && supplier.services.find((service) => service.service_id === search.service_id));
  const [selectedTimeslots, setSelectedTimeslots] = useState([]);

  const serviceIdFromUrl = useQuery().get('service');

  React.useEffect(() => {
    if (serviceIdFromUrl) {
      const s = supplier.services.find((ser) => ser.service_id === parseInt(serviceIdFromUrl, 10));
      if (s) {
        setSelectedService(s);
        addSearchOption({
          key: 'service_id',
          data: s.service_id,
        });
      }
    }
  }, [serviceIdFromUrl]);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  useEffect(() => {
    if (search === undefined || search.date === undefined) {
      addSearchOption({
        key: 'date',
        data: moment().format('YYYY-MM-DD'),
      });
    }
  }, []);

  useEffect(() => {
    addSearchOption({
      key: 'date',
      data: moment(selectedDate).format('YYYY-MM-DD'),
    });
  }, [selectedDate]);

  useEffect(() => {
    setSelectedTimeslots([]);

    if (supplier !== undefined && selectedDate !== undefined && selectedService !== undefined) {
      fetchTimeSlots({
        service_id: selectedService.service_id,
        supplier_id: supplier.id,
        date: selectedDate.format('YYYY-MM-DD'),
      });
    }
  }, [selectedDate, selectedService]);

  const toogleTimeSlot = (timeslot) => {
    const slots = [...selectedTimeslots];
    const index = slots.indexOf(timeslot);

    if (index >= 0) {
      slots.splice(index, 1);
    } else {
      slots.push(timeslot);
    }

    setSelectedTimeslots(slots);
  };

  const renderDateSelection = useCallback(() => (
    <div className="supplier-calendar-date-selection" style={{ display: 'flex' }}>
      <Box boxShadow={2} style={{ borderRadius: 4 }}>
        <DatePicker
          selected={selectedDate._d}
          minDate={moment()._d}
          onChange={(date) => setSelectedDate(moment(date))}
          locale="it"
          dateFormat="d MMM yyyy"
        />
      </Box>
    </div>
  ), [supplier, selectedDate]);

  const returnNameOfService = (services, supplier) =>{
    return services.find(x=>x.supplier_id == supplier.id).name;
  }

  const renderServiceSelection = useCallback(() => (
    <div className="supplier-calendar-date-selection" style={{ display: 'flex' }}>
      {supplier && supplier.services && (
         <p>{returnNameOfService(supplier.services, supplier)}</p>
      )}
    </div>
  ), [supplier, selectedService]);

  const renderDaysBar = useCallback(() => {
    const date = moment(selectedDate._d).subtract(4, 'day');

    return (
      <div className="days-bar">
        {[...Array(7).keys()].map((key) => {
          date.add(1, 'day');
          const currentDate = moment(date._d);
          return (
            <div className={key === 3 ? 'day-wrapper selected' : 'day-wrapper'} key={key}>
              <Box
                onClick={() => {
                  if (moment(currentDate._d).diff(moment(), 'days') >= 0) {
                    setSelectedDate(moment(currentDate._d));
                  }
                }}
                boxShadow={2}
                className="day"
                key={key}
              >
                <p className="day-number">{currentDate.format('DD')}</p>
                <p className="day-name">{isSmallScreen ? currentDate.format('ddd') : currentDate.format('dddd')}</p>
              </Box>
            </div>
          );
        })}
      </div>
    );
  }, [selectedDate, isSmallScreen]);

  const renderDescription = React.useCallback(() => (selectedService ? (
    <>
      <p style={{ textAlign: 'left', marginTop: 20 }}>
        <b>Descrizione servizio</b>
      </p>
      <p style={{ textAlign: 'left' }}>
        {selectedService.description}
      </p>
    </>
  ) : null), [selectedService]);

  const renderTimeSlots = useCallback(() => {
    if (timeslots === undefined) {
      if (selectedService === undefined) return null;
      return <CustomLoader />;
    }

    return (
      <div className="timeslots-wrapper">
        {timeslots.map((timeslot) => (
          <div className="timeslot-wrapper">
            <Box
              onClick={() => {
                // addSearchOption({
                //   key: 'timeslot',
                //   data: timeslot,
                // });

                toogleTimeSlot(timeslot);
              }}
              boxShadow={2}
              className={(selectedTimeslots !== undefined && selectedTimeslots.includes(timeslot)) ? 'timeslot selected' : 'timeslot'}
            >
              <span>{timeslot.start}</span>
              <span>{timeslot.end}</span>
            </Box>

          </div>
        ))}
      </div>
    );
  }, [timeslots, selectedTimeslots]);

  return supplier ? (
    <div className="supplier-calendar-wrapper">
      <div style={{ display: 'flex' }}>
        {renderDateSelection()}
        <div style={{ marginLeft: 10 }}>
          {renderServiceSelection()}
        </div>
      </div>

      {renderDaysBar()}
      {renderTimeSlots()}

      {renderDescription()}

      <Button
        disabled={selectedTimeslots.length === 0}
        className="send-request-button"
        variant="yellow"
        onClick={() => {
          addSearchOption({
            key: 'timeslots',
            data: selectedTimeslots,
          });

          if (onConfirm) onConfirm();
        }}
      >
        Invia richiesta
      </Button>

    </div>
  ) : null;
};

export default SupplierCalendar;
