import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PasswordReset from './PasswordReset';

import { sendUserNewPassword } from '../../../store/actions';

const mapStateToProps = () => ({});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  sendUserNewPassword,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordReset);
