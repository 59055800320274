/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import HomeSearch from '../../components/home-search';
import colors from '../../res/colors';
import { getMediaUrl } from '../../libs/utils';

import HomeServiceSelectionModal from '../../components/home-service-selection-modal';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  topImageWrapper: {
    textAlign: 'right',
  },
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBox: {
    width: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  searchInput: {
    borderBottom: 'none',
  },
}));


const LoggedMain = ({
  services, serviceCategories, flushParam, search,
}) => {
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();


  useEffect(() => {
    if (search) {
      flushParam('search');
    }
  }, []);

  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid className={classes.searchWrapper} item xs={12}>
          <div className="homesearch-wrapper">
            <HomeSearch services={services} serviceCategories={serviceCategories} />
          </div>
        </Grid>
      </Grid>

      <Container>
        <Grid container>
          {serviceCategories && serviceCategories.map((serviceCategory) => (
            <Grid className={classes.searchWrapper} item xs={6} md={3}>
              <div
                onClick={() => {
                  // performSearch();
                  // history.push('/search');
                  setSelectedCategory(serviceCategory.id);
                  setShowCategoryModal(true);
                }}
                style={{
                  cursor: 'pointer', position: 'relative', width: '100%', height: 200, margin: 15, backgroundImage: `url(${getMediaUrl(serviceCategory.media.main)})`, backgroundSize: 'cover', backgroundPosition: '50% 50%', borderRadius: 10, overflow: 'hidden',
                }}
              >
                <h6 style={{
                  position: 'absolute', bottom: 10, right: 10, backgroundColor: colors.secondary.normal, padding: '5px 10px',
                }}
                >
                  {serviceCategory.name}
                </h6>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>

      <HomeServiceSelectionModal
        visible={showCategoryModal}
        category={selectedCategory}
        onClose={() => {
          setShowCategoryModal(false);
        }}
      />


    </div>
  );
};

export default withRouter(LoggedMain);
