/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useCallback, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Grid } from '@material-ui/core';


import { withRouter } from 'react-router-dom';
import Loader from '../../../components/loader';

import AccountSettings from './account';
import AccessibilitySettings from './accessibility';
import AddressesSettings from './addresses';
import NotificationsSettings from './notifications';
import PaymentsSettings from './payments';
import SecuritySettings from './security';
import NeedySettings from './needy';

import './styles.css';

const needySettingsMenuItems = [
  { title: 'Account', key: 'account' },
  { title: 'Privacy e sicurezza', key: 'sicurezza' },
  // { title: 'Notifiche', key: 'notifications' },
  //{ title: 'Metodi di pagamento', key: 'metodi-pagamento' },
  { title: 'Indirizzi', key: 'indirizzi' },
  // { title: 'Accessibilità', key: 'accessibility' },
];

const carerSettingsMenuItems = [
  ...needySettingsMenuItems,
  { title: 'Anziani', key: 'anziani' },
];


const supplierSettingsMenuItems = [
  { title: 'Account', key: 'account' },
  { title: 'Privacy e sicurezza', key: 'sicurezza' },
  // { title: 'Notifiche', key: 'notifications' },
  // { title: 'Accessibilità', key: 'accessibility' },
];


const Settings = ({ user, history }) => {
  const [setting, setSetting] = useState();

  useEffect(() => {
    const items = history.location.pathname.split('/');

    if (items.length > 3) {
      setSetting(items[3]);
    } else if (items.length === 3 && items[2] === 'preferenze') {
      history.push('/dashboard/preferenze/account');
    }
  }, [history.location]);


  const renderSettingContent = useCallback(() => {
    if (setting === 'account') return <AccountSettings />;
    if (setting === 'sicurezza') return <SecuritySettings />;
    if (setting === 'notifiche') return <NotificationsSettings />;
    //if (setting === 'metodi-pagamento') return <PaymentsSettings />;
    if (setting === 'indirizzi') return <AddressesSettings />;
    if (setting === 'accessibilita') return <AccessibilitySettings />;
    if (setting === 'anziani') return <NeedySettings />;

    return <Loader />;
  }, [setting]);

  const renderSettingsMenu = useCallback(() => {
    const items = (() => {
      if (user.data.type === 'carer') return carerSettingsMenuItems;
      if (user.data.type === 'supplier') return supplierSettingsMenuItems;

      return needySettingsMenuItems;
    })();

    return items.map((item) => (
      <div
        onClick={() => {
          history.push(`/dashboard/preferenze/${item.key}`);
        }}
        className={item.key === setting ? 'dashboard-settings-menu-item selected' : 'dashboard-settings-menu-item '}
      >
        <span>{item.title}</span>
      </div>
    ));
  }, [setting]);

  return (
    <Container className="settings-wrapper">
      <Grid container>
        <Grid className="settings-menu-wrapper" xs={12} md={4}>
          <h4>Nome utente</h4>
          {renderSettingsMenu()}
        </Grid>
        <Grid xs={12} md={8}>
          {renderSettingContent()}
        </Grid>
      </Grid>

    </Container>
  );
};
export default withRouter(Settings);
