import React, { useCallback, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { registerLocale } from 'react-datepicker';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';


import { FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import it from 'date-fns/locale/it';

import './styles.css';

import { withRouter } from 'react-router-dom';
import colors from '../../res/colors';
import { getFullAddress, formatEventDatetime } from '../../libs/utils';

import SupplierMap from '../supplier-map';

import CustomCheckbox from '../custom-checkbox';
import Loader from '../loader';

registerLocale('es', it);

const CustomInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.secondary.normal,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.secondary.normal,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.primary.normal,
      },
      '&:hover fieldset': {
        borderColor: colors.secondary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.secondary.normal,
      },
    },
  },
})(TextField);


const UnloggedReservationStepForm = ({
  onClose,
  search,
  services,
  supplier,
  history,
  addSearchOption,
  saveReservation,
  sendGuestReservationRequest,
}) => {
  const [step, setStep] = useState(1);
  const [notes, setNotes] = useState();
  const [addressInput, setAddressInput] = useState('');
  const [address, setAddress] = useState();
  const [policy] = useState(false);

  const renderAddressForm = useCallback(() => (
    <>
      <div style={{ textAlign: 'left' }}>
        <PlacesAutocomplete
          style={{ width: '100%' }}
          value={addressInput}
          onChange={setAddressInput}
          onSelect={async (ad) => {
            setAddressInput(ad);
            const data = await geocodeByAddress(ad);

            setAddress({
              address: `${data[0].address_components.find((item) => item.types.includes('route')).long_name}, ${data[0].address_components.find((item) => item.types.includes('street_number')).long_name}`,
              city: data[0].address_components.find((item) => item.types.includes('locality')).long_name,
              postalCode: data[0].address_components.find((item) => item.types.includes('postal_code')).long_name,
              coordinates: {
                lat: data[0].geometry.location.lat(),
                lng: data[0].geometry.location.lng(),
              },
            });
          }}
        >
          {({
            getInputProps, suggestions, getSuggestionItemProps, loading,
          }) => (
            <div>
              <CustomInput
                label="Indirizzo"
                variant="outlined"
                color={colors.primary.normal}
                {...getInputProps({
                  placeholder: 'Cerca indirizzo ...',
                  className: 'location-search-input',
                })}
              />

              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                    // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <div style={{ width: '100%', textAlign: 'center', marginTop: 30 }}>
        <Button className="deny-button" variant="red" onClick={() => onClose && onClose()}>Annulla</Button>
        <Button
          className="continue-button"
          variant="green"
          onClick={() => {
            addSearchOption({
              key: 'address',
              data: address,
            });
            setStep(step + 1);
          }}
        >
          Continua
        </Button>
      </div>
    </>
  ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [step, addressInput, address]);

  const renderUserForm = useCallback(() => (
    <Formik
      initialValues={{
        first_name: '', last_name: '', email: '', policy: false,
      }}
      validate={(values) => {
        const errors = {};

        if (!values.policy) {
          errors.policy = 'Accettare policy';
        }

        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Indirizzo mail non valido';
        }
        return errors;
      }}
      onSubmit={(values) => {
        addSearchOption({
          key: 'first_name',
          data: values.first_name,
        });

        addSearchOption({
          key: 'last_name',
          data: values.last_name,
        });

        addSearchOption({
          key: 'email',
          data: values.email,
        });

        setStep(step + 1);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form style={{ display: 'block' }} onSubmit={handleSubmit} autoComplete="off">
          <div className="form-input-wrapper">
            <CustomInput
              onChange={handleChange('first_name')}
              onBlur={handleBlur('first_name')}
              value={values.first_name}
              required
              id="first-name"
              label="Nome"
              variant="outlined"
            />
          </div>
          <div className="form-input-wrapper">
            <CustomInput
              required
              onChange={handleChange('last_name')}
              onBlur={handleBlur('last_name')}
              value={values.last_name}
              id="last-name"
              label="Cognome"
              variant="outlined"
            />
          </div>
          <div className="form-input-wrapper">
            <CustomInput
              required
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
              value={values.email}
              id="email"
              label="Email"
              variant="outlined"
            />
          </div>

          <div className="policy-checkbox-wrapper">
            <FormControlLabel
              control={
                <CustomCheckbox checked={values.policy} onChange={handleChange('policy')} value="checkedA" />
              }
              label="Dolore ipsum anim tempor est cupidatat cillum ullamco sit id et."
            />
          </div>


          <div className="errors-wrapper">
            {errors.email && touched.email && errors.email}
          </div>
          <div style={{ width: '100%', textAlign: 'center', marginTop: 30 }}>
            <Button className="deny-button" variant="red" onClick={() => onClose && onClose()}>Annulla</Button>
            <Button
              className="continue-button"
              variant="green"
              onClick={handleSubmit}
            >
              Continua
            </Button>
          </div>
        </form>
      )}
    </Formik>
  ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [step]);

  const renderNotesForm = useCallback(() => (
    <>
      <div>
        <CustomInput
          onChange={(event) => setNotes(event.target.value)}
          value={notes}
          id="notes"
          label="Note"
          variant="outlined"
        />
      </div>
      <div style={{ width: '100%', textAlign: 'center', marginTop: 30 }}>
        <Button className="deny-button" variant="red" onClick={() => onClose && onClose()}>Annulla</Button>
        <Button
          className="continue-button"
          variant="green"
          onClick={() => {
            addSearchOption({
              key: 'notes',
              data: notes,
            });

            if (search.address) {
              if (search.name) {
                setStep(step + 2);
              } else {
                setStep(step + 3);
              }
            } else {
              setStep(step + 1);
            }
          }}
        >
          Continua
        </Button>
      </div>
    </>
  ), [step, notes]);

  const renderReservationRequestRecap = useCallback(() => {
    const service = supplier.services.find((s) => s.service_id === search.service_id);
    const fullNameService = 
    (search.first_name !== undefined && search.last_name !== undefined ||
      search.first_name !== "" && search.last_name !== "" ) ? "" :  search.first_name +  search.last_name;

    return (
      <>
        <div className="reservation-request-recap-wrapper" style={{ paddigTop: 0 }}>
         
        {fullNameService != "" && (
          <div className="reservation-request-recap-item">
            <h6>Servizio richiesto da</h6>
            <p>{`${fullNameService}`}</p>
          </div>
        )}

          <div className="reservation-request-recap-item">
            <h6>Servizio richiesto</h6>
            <p>
              {service.name}
            </p>
          </div>
          <div className="reservation-request-recap-item">
            <h6>Descrizione servizio richiesto</h6>
            <p>{service.description}</p>
          </div>
          <div className="reservation-request-recap-item">
            <h6>Fornitore</h6>
            <p>{supplier.info.rag_soc}</p>
          </div>
          <div className="reservation-request-recap-item">
            <h6>Quando</h6>
            <p>{formatEventDatetime(search)}</p>
          </div>
          <div className="reservation-request-recap-item">
            <h6>Dove</h6>
            <p>{getFullAddress(search.address)}</p>
            <SupplierMap
              lat={search.address.coordinates.lat}
              lng={search.address.coordinates.lng}
            />
          </div>
          {search.notes && (
          <div className="reservation-request-recap-item" style={{ marginTop: 20 }}>
            <h6>Note</h6>
            <p>{search.notes}</p>
          </div>
          )}
          <div className="reservation-request-recap-item" style={{ marginTop: 20 }}>
            <h6>Preventivo costi</h6>
            {`€ ${parseFloat(
              ((service.price * service.duration) / 6000) * search.timeslots.length,
            ).toFixed(2)}`}
          </div>
        </div>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 30 }}>
          <Button className="deny-button" variant="red" onClick={() => onClose && onClose()}>Annulla</Button>
          <Button
            className="continue-button"
            variant="green"
            onClick={() => {
              saveReservation();
              history.push('/registrazione');
            }}
          >
            Accedi per continuare
          </Button>

          <p style={{ marginTop: 20 }}>
            Non vuoi registrarti? Manda la richiesta al Customer Service e verrai ricontattato.
          </p>
          <Button
            variant="yellow"
            onClick={() => {
              setStep(step + 1);
            }}
          >
      Continua
          </Button>
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, notes, policy, services, search, address]);

  const renderUserInfoForm = useCallback(() => (
    <Formik
      initialValues={{
        first_name: '', last_name: '', email: '', phone: '', policy: false,
      }}
      validate={(values) => {
        const errors = {};

        if (!values.policy) {
          errors.policy = 'Accettare policy';
        }

        if (!values.first_name) {
          errors.first_name = 'Nome obbligatorio';
        }

        if (!values.last_name) {
          errors.last_name = 'Cognome obbligatorio';
        }

        if (!values.phone) {
          errors.phone = 'Numero di telefono mancante';
        }

        if (!values.email) {
          errors.email = 'Indirizzo email obbligatorio';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Indirizzo mail non valido';
        }
        return errors;
      }}
      onSubmit={(values) => {
        addSearchOption({
          key: 'first_name',
          data: values.first_name,
        });

        addSearchOption({
          key: 'last_name',
          data: values.last_name,
        });

        addSearchOption({
          key: 'email',
          data: values.email,
        });

        addSearchOption({
          key: 'phone',
          data: values.phone,
        });

        sendGuestReservationRequest();
        history.push('/prenotazione-customer-service-confermata');
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form style={{ display: 'block' }} onSubmit={handleSubmit} autoComplete="off">
          <div className="form-input-wrapper">
            <CustomInput
              onChange={handleChange('first_name')}
              onBlur={handleBlur('first_name')}
              value={values.first_name}
              required
              id="first-name"
              label="Nome"
              variant="outlined"
            />
          </div>
          <div className="form-input-wrapper">
            <CustomInput
              required
              onChange={handleChange('last_name')}
              onBlur={handleBlur('last_name')}
              value={values.last_name}
              id="last-name"
              label="Cognome"
              variant="outlined"
            />
          </div>
          <div className="form-input-wrapper">
            <CustomInput
              required
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
              value={values.email}
              id="email"
              label="Email"
              variant="outlined"
            />
          </div>

          <div className="form-input-wrapper">
            <CustomInput
              required
              onChange={handleChange('phone')}
              onBlur={handleBlur('phone')}
              value={values.phone}
              id="phone"
              label="Numero di telefono"
              variant="outlined"
            />
          </div>

          <div className="policy-checkbox-wrapper">
            <CustomCheckbox id="privacy" style={{ display: 'table-cell' }} checked={values.policy} onChange={handleChange('policy')} />
            <label htmlFor="privacy" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                        Dichiaro di aver letto ed approvato la
              {' '}
              <a target="_blank" rel="noopener noreferrer" href="/docs/privacy_link.pdf">privacy policy</a>
            </label>
          </div>

          <div className="errors-wrapper" style={{ color: 'red' }}>
            {Object.entries(errors).map(([_, value]) => <p>{value}</p>)}
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button disabled={!values.policy && Object.keys(errors).length !== 0} type="submit" onClick={handleSubmit} variant="yellow">Manda al customer center</Button>
          </div>
        </form>
      )}
    </Formik>
  ), [address]);


  const renderHeader = useCallback(() => (
    <h5 style={{ textAlign: 'center' }}>Prenotazione</h5>
  ), [step]);

  const renderContent = useCallback(() => {
    if (step === 1) return renderNotesForm();
    if (step === 2) return renderAddressForm();
    if (step === 3) return renderUserForm();
    if (step === 4) return renderReservationRequestRecap();
    if (step === 5) return renderUserInfoForm();


    return null;
  }, [step, policy, services, addressInput, address, search, notes]);

  return (
    <>
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        {renderHeader()}
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">
        {services ? renderContent() : <Loader />}
      </Modal.Body>
    </>
  );
};

export default withRouter(UnloggedReservationStepForm);
