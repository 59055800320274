import React, { useCallback, useState } from 'react';
import { Modal, Button as BootstrapButton } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Formik } from 'formik';
import { Button } from '@material-ui/core';

import it from 'date-fns/locale/it';
import moment from 'moment';

import './styles.css';

import { withRouter } from 'react-router-dom';
import CompletationBar from '../completation-bar';
import CustomCheckbox from '../custom-checkbox';
import CustomInput from '../custom-input';

registerLocale('es', it);


const stepsData = [
  {
    title: 'Quando hai bisogno del servizio?',
  },
  {
    title: 'Dove vuoi che sia svolto il servizio?',
  },
  // {
  //   title: 'Immettere i seguenti dati',
  // },
];

const UnloggedPreReservationStepForm = ({ history, addSearchOption }) => {
  const [step, setStep] = useState(1);
  const [date, setDate] = useState(moment()._d);
  const [addressInput, setAddressInput] = useState('');
  const [address, setAddress] = useState('');

  const handleNext = () => {
    if (step === 2) {
      submit();
      return;
    }

    if (step < stepsData.length) { setStep(step + 1); }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const submit = useCallback(() => {
    addSearchOption({
      key: 'address',
      data: address,
    });

    // addSearchOption({
    //   key: 'first_name',
    //   data: values.first_name,
    // });

    // addSearchOption({
    //   key: 'last_name',
    //   data: values.last_name,
    // });

    // addSearchOption({
    //   key: 'email',
    //   data: values.email,
    // });

    // addSearchOption({
    //   key: 'phone',
    //   data: values.phone,
    // });


    addSearchOption({
      key: 'date',
      data: moment(date).format('YYYY-MM-DD'),
    });

    // performSearch();

    history.push('/ricerca');
  }, [address, date]);


  const renderHeaderContent = useCallback(() => (
    <>
      {step > 1 && (
        <div>
          <KeyboardBackspaceIcon fontSize="small" />
          <Button onClick={handleBack}>torna indietro</Button>
        </div>
      )}
      <div>
        <h5>{stepsData[step - 1].title}</h5>
      </div>
    </>
  ), [step]);


  const renderDateSelectionStepContent = useCallback(() => (
    <>
      <DatePicker
        selected={date}
        minDate={moment()._d}
        onChange={(d) => setDate(d)}
        locale="it"
        inline
      />
      <BootstrapButton onClick={handleNext} variant="yellow">Continua</BootstrapButton>
    </>
  ), [step, date]);

  const renderAddressSelectionContent = useCallback(() => (
    <>
      <div style={{ textAlign: 'left' }}>
        <CustomInput
          showInModal={false}
          required
          title="Seleziona il tuo indirizzo di residenza"
          type="address"
          onChange={(a) => {
            setAddressInput(`${a.address}, ${a.postalCode} ${a.city}`);
            setAddress(a);
          }}
          value={addressInput}
          id="address"
          label="Indirizzo"
          variant="outlined"
        />
      </div>
      <BootstrapButton disabled={address === undefined || address === ''} onClick={handleNext} variant="yellow">Continua</BootstrapButton>
    </>
  ), [step, addressInput, address]);

  const renderUserInfoForm = useCallback(() => (
    <Formik
      initialValues={{
        first_name: '', last_name: '', email: '', phone: '', policy: false,
      }}
      validate={(values) => {
        const errors = {};

        if (!values.policy) {
          errors.policy = 'Accettare policy';
        }

        if (!values.first_name) {
          errors.first_name = 'Nome obbligatorio';
        }

        if (!values.last_name) {
          errors.last_name = 'Cognome obbligatorio';
        }

        if (!values.phone) {
          errors.phone = 'Numero di telefono mancante';
        }

        if (!values.email) {
          errors.email = 'Indirizzo email obbligatorio';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Indirizzo mail non valido';
        }
        return errors;
      }}
      onSubmit={(values) => {
        submit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form style={{ display: 'block' }} onSubmit={handleSubmit} autoComplete="off">
          <div className="form-input-wrapper">
            <CustomInput
              onChange={handleChange('first_name')}
              onBlur={handleBlur('first_name')}
              value={values.first_name}
              required
              id="first-name"
              label="Nome"
              variant="outlined"
            />
          </div>
          <div className="form-input-wrapper">
            <CustomInput
              required
              onChange={handleChange('last_name')}
              onBlur={handleBlur('last_name')}
              value={values.last_name}
              id="last-name"
              label="Cognome"
              variant="outlined"
            />
          </div>
          <div className="form-input-wrapper">
            <CustomInput
              required
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
              value={values.email}
              id="email"
              label="Email"
              variant="outlined"
            />
          </div>

          <div className="form-input-wrapper">
            <CustomInput
              required
              onChange={handleChange('phone')}
              onBlur={handleBlur('phone')}
              value={values.phone}
              id="phone"
              label="Numero di telefono"
              variant="outlined"
            />
          </div>

          <div className="policy-checkbox-wrapper">
            <CustomCheckbox id="privacy" style={{ display: 'table-cell' }} checked={values.policy} onChange={handleChange('policy')} />
            <label htmlFor="privacy" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                        Dichiaro di aver letto ed approvato la
              {' '}
              <a target="_blank" rel="noopener noreferrer" href="/docs/privacy_link.pdf">privacy policy</a>
            </label>
          </div>

          <div className="errors-wrapper" style={{ color: 'red' }}>
            {Object.entries(errors).map(([_, value]) => <p>{value}</p>)}
          </div>
          <div>
            <BootstrapButton disabled={!values.policy && Object.keys(errors).length !== 0} type="submit" onClick={handleSubmit} variant="yellow">Continua</BootstrapButton>
          </div>
        </form>
      )}
    </Formik>
  ), [address]);


  const renderContent = useCallback(() => {
    if (step === 1) return renderDateSelectionStepContent();
    if (step === 2) return renderAddressSelectionContent();
    // if (step === 3) return renderUserInfoForm();

    return null;
  }, [step, date, address, addressInput]);

  const renderFooter = useCallback(() => (
    <>
      <CompletationBar level={(step * 100) / stepsData.length} />
    </>
  ), [step]);

  return (
    <>
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        {renderHeaderContent()}
      </Modal.Header>
      <Modal.Body className="pre-reservation-from-modal-content">
        {renderContent()}
      </Modal.Body>
      <Modal.Footer style={{ borderWidth: 0, marginTop: 20 }}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {renderFooter()}
        </div>
      </Modal.Footer>
    </>
  );
};

export default withRouter(UnloggedPreReservationStepForm);
