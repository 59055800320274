import moment from 'moment';

const currentDate = moment.utc(new Date().setHours(0, 0));


export const obtainDate = (date) => {
  if (!date) return date;
  const d = moment.utc(date);
  return {
    moment: d,
    formatted: d.format('DD-MM-YYYY'),
    diff: d.diff(currentDate, 'days'),
  };
};

export const getMediaUrl = (url) => {
  if (url === undefined || url === null) return null;

  if (url.includes('http')) {
    return url;
  }

  if (url.includes('my-seren-care.s3')) {
    return `https://${url}`;
  }

  return `${process.env.REACT_APP_BASE_URL}${url}`;
};

export const getFullAddress = (obj) => {
  if (obj === undefined) return '';

  if ('address' in obj) {
    return `${obj.address}, ${obj.postalCode} ${obj.city}`;
  }

  return `${obj.line1 ? obj.line1 : ''}${obj.line2 ? ` ${obj.line2}` : ''}${obj.line3 ? ` ${obj.line3}` : ''}, ${obj.postal_code} ${obj.city.name}`;
};


export const isValidEmail = (email) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};


export const formatPrice = (price) => `${(parseFloat(price) / 100).toFixed(2)}`;

export const getPrice = (price) => `${(parseFloat(price) / 100).toFixed(2)}€ / hr`;


export const parseTotalPrice = (price, duration, timeslotsCount) => `${parseFloat(
  ((price * duration) / 6000) * timeslotsCount,
).toFixed(2)}€`;


export const getRandomString = (length = 10) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i + 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};


export const translateStatus = (string) => {
  const map = {
    pending: 'In attesa di approvazione',
    'in-progress': 'In corso',
    completed: 'Completata',
    refused: 'Rifiutata',
    accepted: 'Accettata',
    booked: 'Confermato',
  };


  return string in map ? map[string] : string;
};

export const handleResultActions = (action, check) => {
  if (check) {
    if (action.onSuccess) {
      action.onSuccess();
    }
  } else if (action.onError) {
    action.onError();
  }
};


export const formatKilometers = (value) => parseFloat(value).toFixed(2);

export const splitMulti = (str, separators) => {
  const tempChar = 't3mp';
  const re = new RegExp(`\\b(${separators.join('|')})\\b`, 'g');
  const strLocal = str.replace(re, tempChar).split(tempChar);
  return strLocal.map((el) => el.trim()).filter((el) => el.length > 0);
};


export const formatEventDatetime = (event, showDate = true) => {
  event.timeslots.sort((a, b) => {
    if (a.start > b.start) {
      return 1;
    } if (a.start < b.start) {
      return -1;
    }

    return 0;
  });

  const slotsStr = event.timeslots.reduce((acc, current, index) => {
    if (index - 1 >= 0) {
      if (current.start === event.timeslots[index - 1].end) { // consecutive slots
        if (event.timeslots.length - 1 === index) {
          return `${acc} - ${current.end}`;
        }

        return `${acc}`;
      }

      if (event.timeslots.length - 1 === index) { // final slot
        return `${acc} - ${event.timeslots[index - 1].end}, ${current.start} - ${current.end} `;
      }

      return `${acc} - ${event.timeslots[index - 1].end}, ${current.start}`;
    }

    if (event.timeslots.length === 1) {
      return `${current.start} - ${current.end}`;
    }

    return current.start;
  }, '');

  return `${showDate ? `${moment(event.date).format('DD/MM/YYYY')}\n` : ''}${slotsStr}`;
};


export const getEventDates = (event) => {
  const dateTuples = [];
  let currentTuple = null;

  event.timeslots.forEach((ts) => {
    if (currentTuple === null) {
      currentTuple = {
        start: moment(`${event.date} ${ts.start}`),
        end: moment(`${event.date} ${ts.end}`),
      };
    } else {
      const currentStartDate = moment(`${event.date} ${ts.start}`);
      if (!(currentStartDate.isSame(currentTuple.end))) {
        dateTuples.push(currentTuple);
        currentTuple = {
          start: moment(`${event.date} ${ts.start}`),
          end: moment(`${event.date} ${ts.end}`),
        };
      } else {
        currentTuple.end = moment(`${event.date} ${ts.end}`);
      }
    }
  });

  dateTuples.push(currentTuple);

  return dateTuples;
};

export const getStatus = (event) => event.status;


// export const getStatus = (event) => {
//   if (event.reservation_request) {
//     return event.reservation_request.status;
//   }

//   return event.status;
// };


export const parseSupplierTimeslots = (timeslots) => {
  const t = [];

  t[0] = timeslots.monday ? ({
    morning: timeslots.monday[0] ? {
      start: timeslots.monday[0].split('-')[0], end: timeslots.monday[0].split('-')[1],
    } : { start: '', end: '' },
    afternoon: timeslots.monday[1] ? {
      start: timeslots.monday[1].split('-')[0], end: timeslots.monday[1].split('-')[1],
    } : { start: '', end: '' },
  }) : { morning: { start: '', end: '' }, afternoon: { start: '', end: '' } };

  t[1] = timeslots.tuesday ? ({
    morning: timeslots.tuesday[0] ? {
      start: timeslots.tuesday[0].split('-')[0], end: timeslots.tuesday[0].split('-')[1],
    } : { start: '', end: '' },
    afternoon: timeslots.tuesday[1] ? {
      start: timeslots.tuesday[1].split('-')[0], end: timeslots.tuesday[1].split('-')[1],
    } : { start: '', end: '' },
  }) : { morning: { start: '', end: '' }, afternoon: { start: '', end: '' } };

  t[2] = timeslots.wednesday ? ({
    morning: timeslots.wednesday[0] ? {
      start: timeslots.wednesday[0].split('-')[0], end: timeslots.wednesday[0].split('-')[1],
    } : { start: '', end: '' },
    afternoon: timeslots.wednesday[1] ? {
      start: timeslots.wednesday[1].split('-')[0], end: timeslots.wednesday[1].split('-')[1],
    } : { start: '', end: '' },
  }) : { morning: { start: '', end: '' }, afternoon: { start: '', end: '' } };

  t[3] = timeslots.thursday ? ({
    morning: timeslots.thursday[0] ? {
      start: timeslots.thursday[0].split('-')[0], end: timeslots.thursday[0].split('-')[1],
    } : { start: '', end: '' },
    afternoon: timeslots.thursday[1] ? {
      start: timeslots.thursday[1].split('-')[0], end: timeslots.thursday[1].split('-')[1],
    } : { start: '', end: '' },
  }) : { morning: { start: '', end: '' }, afternoon: { start: '', end: '' } };

  t[4] = timeslots.friday ? ({
    morning: timeslots.friday[0] ? {
      start: timeslots.friday[0].split('-')[0], end: timeslots.friday[0].split('-')[1],
    } : { start: '', end: '' },
    afternoon: timeslots.friday[1] ? {
      start: timeslots.friday[1].split('-')[0], end: timeslots.friday[1].split('-')[1],
    } : { start: '', end: '' },
  }) : { morning: { start: '', end: '' }, afternoon: { start: '', end: '' } };

  t[5] = timeslots.saturday ? ({
    morning: timeslots.saturday[0] ? {
      start: timeslots.saturday[0].split('-')[0], end: timeslots.saturday[0].split('-')[1],
    } : { start: '', end: '' },
    afternoon: timeslots.saturday[1] ? {
      start: timeslots.saturday[1].split('-')[0], end: timeslots.saturday[1].split('-')[1],
    } : { start: '', end: '' },
  }) : { morning: { start: '', end: '' }, afternoon: { start: '', end: '' } };

  t[6] = timeslots.sunday ? ({
    morning: timeslots.sunday[0] ? {
      start: timeslots.sunday[0].split('-')[0], end: timeslots.sunday[0].split('-')[1],
    } : { start: '', end: '' },
    afternoon: timeslots.sunday[1] ? {
      start: timeslots.sunday[1].split('-')[0], end: timeslots.sunday[1].split('-')[1],
    } : { start: '', end: '' },
  }) : { morning: { start: '', end: '' }, afternoon: { start: '', end: '' } };

  return t;
};

export const zeroPad = (num, size) => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};


export const parseDayOfTimetable = (index, day) => {
  const d = [];
  const morning = day.morning.start !== '' && day.morning.end !== '' ? `${zeroPad(day.morning.start, 5)}-${zeroPad(day.morning.end, 5)}` : undefined;
  const afternoon = day.afternoon.start !== '' && day.afternoon.end !== '' ? `${zeroPad(day.afternoon.start, 5)}-${zeroPad(day.afternoon.end, 5)}` : undefined;

  if (morning) d.push(morning);
  if (afternoon) d.push(afternoon);

  return { [daysMap[index]]: d };
};

export const daysMap = {
  0: 'monday',
  1: 'tuesday',
  2: 'wednesday',
  3: 'thursday',
  4: 'friday',
  5: 'saturday',
  6: 'sunday',
};
