import React, { useCallback, useState } from 'react';
import {
  Modal, Button,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import CustomInput from '../custom-input';

import './styles.css';

const AddNeedyModal = ({
  visible, onClose, contact: contactToEdit, addSupplierContact,
}) => {
  const [initialValues] = useState(contactToEdit ? {
    type: contactToEdit.type,
    value: contactToEdit.value,
  } : {
    type: '',
    value: '',
  });

  const ValidationSchema = Yup.object().shape({
    type: Yup.string()
      .min(2, 'Il tipo contiene pochi caratteri')
      .max(50, 'Il tipo contiene troppi caratteri')
      .required('Campo obbligatorio'),
    value: Yup.string()
      .min(2, 'Il valore contiene pochi caratteri')
      .max(50, 'Il valore contiene troppi caratteri')
      .required('Campo obbligatorio'),
  });

  const renderForm = useCallback(() => (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        addSupplierContact(values);
        onClose();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container>
            <Grid item xs={12}>
              <div className="form-input-wrapper">
                <CustomInput
                  type="text"
                  required
                  onChange={handleChange('type')}
                  onBlur={handleBlur('type')}
                  value={values.type}
                  disabled={contactToEdit}
                  id="type"
                  label="Tipo"
                  variant="outlined"
                  error={touched.type && errors.type}
                />
                <p style={{ marginTop: 10 }}>Es. Telefono Lavoro, Telefono Ufficio, Whatsapp</p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-input-wrapper">
                <CustomInput
                  type="text"
                  required
                  onChange={handleChange('value')}
                  onBlur={handleBlur('value')}
                  value={values.value}
                  id="value"
                  label="Contatto"
                  variant="outlined"
                  error={touched.value && errors.value}
                />
              </div>
            </Grid>
          </Grid>
          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            <Button style={{ marginRight: 15 }} onClick={onClose} variant="yellow">Annulla</Button>
            <Button style={{ marginLeft: 15 }} type="submit" onClick={handleSubmit} disabled={Object.keys(errors).length > 0} variant="green">Invia</Button>
          </div>
        </form>
      )}
    </Formik>
  ), [initialValues]);

  return (
    <Modal show={visible} onHide={onClose} backdrop="static">
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        {contactToEdit ? <h5>Modifica contatto</h5> : <h5>Aggiungi contatto</h5>}
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">
        {renderForm()}
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(AddNeedyModal);
