import { reducerFromMap } from './commons.reducers';
import { SEND_RESERVATION_REQUEST } from '../actions';

const reducerMap = {};

reducerMap[SEND_RESERVATION_REQUEST] = (state) => ({
  ...state,
  loading: true,
});

reducerMap[`${SEND_RESERVATION_REQUEST}_SUCCESS`] = (state) => ({
  ...state,
  loading: false,
});

reducerMap[`${SEND_RESERVATION_REQUEST}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: {
    ...action.payload.data,
  },
});


const reducer = reducerFromMap(reducerMap);

export default reducer;
