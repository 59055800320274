import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { withRouter } from 'react-router-dom';

import CustomInput from '../../../../../components/custom-input';
import colors from '../../../../../res/colors';

const AddNeedyModal = ({
  visible, onClose, addAddresses, throwError,
}) => {
  const [addressInput, setAddressInput] = useState('');
  const [address, setAddress] = useState();

  const handleSubmit = () => {
    addAddresses(address, onClose);
  };

  return (
    <Modal show={visible} onHide={onClose} backdrop="static">
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        <h5>Aggiungi un indirizzo</h5>
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">
        <PlacesAutocomplete
          style={{ width: '100%' }}
          value={addressInput}
          onChange={setAddressInput}
          onSelect={async (ad) => {
            setAddressInput(ad);
            const data = await geocodeByAddress(ad);

            try {
              setAddress({
                address: `${data[0].address_components.find((item) => item.types.includes('route')).long_name}, ${data[0].address_components.find((item) => item.types.includes('street_number')).long_name}`,
                city: data[0].address_components.find((item) => item.types.includes('locality')).long_name,
                postalCode: data[0].address_components.find((item) => item.types.includes('postal_code')).long_name,
                coordinates: {
                  lat: data[0].geometry.location.lat(),
                  lng: data[0].geometry.location.lng(),
                },
              });
            } catch (error) {
              throwError({ message: 'Indirizzo non valido' });
            }
          }}
        >
          {({
            getInputProps, suggestions, getSuggestionItemProps, loading,
          }) => (
            <div className="address-selection-wrapper">
              <CustomInput
                label="Indirizzo"
                variant="outlined"
                color={colors.primary.normal}
                {...getInputProps({
                  placeholder: 'Cerca indirizzo ...',
                  className: 'location-search-input',
                })}
              />

              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                    // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <Button style={{ marginRight: 15 }} onClick={onClose} variant="red">Annulla</Button>
          <Button disabled={address === undefined} style={{ marginLeft: 15 }} type="submit" onClick={handleSubmit} variant="yellow">Continua</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(AddNeedyModal);
