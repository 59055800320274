/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Swal from 'sweetalert2';
import Loader from '../../../../components/loader';
import AddNeedyModal from '../../../../components/add-needy-modal';

import colors from '../../../../res/colors';

const AddressSettings = ({ user, deleteNeedy }) => {
  const [showModal, setShowModal] = useState(false);
  const [needy, setNeedy] = useState();
  const [needyToEdit, setNeedyToEdit] = useState();

  useEffect(() => {
    if (
      user !== undefined
      || user.data !== undefined
      || user.data.needy !== undefined
    ) {
      setNeedy(user.data.needy);
    }
  }, [user]);

  useEffect(() => {
    if (!showModal) {
      setNeedyToEdit(undefined);
    }
  }, [setShowModal]);

  const renderModal = () => (
    <AddNeedyModal
      visible={showModal}
      needyToEdit={needyToEdit}
      onClose={() => setShowModal(false)}
    />
  );

  if (needy === undefined) return <Loader />;

  return (
    <div className="settings-content-wrapper account-settings-wrapper">
       <p> Le prestazioni si terrano all'indirizzo inserito in fase di registrazione.</p>
      {needy.length > 0 ? needy.map(
        (n) => (
          <div>
            <div
              className="setting-item-value"
              onClick={() => {
                setNeedyToEdit(n);
                setShowModal(true);
              }}
            >
              {n.full_name}
            </div>
            <div className="setting-item-edit">
              <p
                style={{ color: '#E46304' }}
                onClick={() => {
                  Swal.fire({
                    title: 'Sei sicuro di voler cancellare questo anziano?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: colors.secondary.normal,
                    cancelButtonColor: colors.red.normal,
                    confirmButtonText: 'Si, cancella!',
                    cancelButtonText: 'Annulla',
                  }).then((result) => {
                    if (result.value) {
                      deleteNeedy(n);
                    }
                  });
                }}
              >
          Elimina
                <HighlightOffIcon style={{ color: '#E46304', fontSize: 16, marginLeft: 8 }}>filled</HighlightOffIcon>
              </p>
            </div>
          </div>
        ),
      ) : <p>Non hai ancora aggiunto nessun anziano</p>}


      <AddCircleIcon
        onClick={() => {
          setNeedyToEdit(undefined);
          setShowModal(true);
        }}
        style={{ color: colors.secondary.normal, fontSize: 40, marginTop: 30 }}
      >
        filled
      </AddCircleIcon>

      {showModal && renderModal()}
    </div>
  );
};

export default AddressSettings;
