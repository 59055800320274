/* eslint-disable react/jsx-no-bind */
import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import { Button } from 'react-bootstrap';

import './style.css';

export default class CalendarToolbar extends Toolbar {
  componentDidMount() {
    // const { view } = this.props;
  }

  render() {
    const { view } = this.props;
    return (
      <div className="calendar-toolbar">
        <div className="rbc-btn-group">
          <Button type="submit" onClick={() => this.navigate('TODAY')} variant="yellow">Oggi</Button>
          <Button type="submit" onClick={() => this.navigate('PREV')} variant="blue">Indietro</Button>
          <Button type="submit" onClick={() => this.navigate('NEXT')} variant="blue">Avanti</Button>
        </div>
        <div className="rbc-toolbar-label">{this.props.label}</div>
        <div className="rbc-btn-group">
          <Button type="submit" onClick={this.view.bind(null, 'month')} variant={view === 'month' ? 'yellow' : 'blue'}>Mese</Button>
          <Button type="submit" onClick={this.view.bind(null, 'week')} variant={view === 'week' ? 'yellow' : 'blue'}>Settimana</Button>
          <Button type="submit" onClick={this.view.bind(null, 'day')} variant={view === 'day' ? 'yellow' : 'blue'}>Giorno</Button>
          <Button type="submit" onClick={this.view.bind(null, 'agenda')} variant={view === 'agenda' ? 'yellow' : 'blue'}>Lista</Button>
        </div>
      </div>
    );
  }
}
