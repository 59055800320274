import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Dropdown,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import CustomInput from '../custom-input';

import './styles.css';
import { formatPrice } from '../../libs/utils';

const AddServiceModal = ({
  visible, onClose, user, services, service, addUserService, deleteServices, updateUserService,
}) => {
  const [selectedService, setSelectedService] = useState();
  const [availableServices, setAvailableServices] = useState();
  const ValidationSchema = Yup.object().shape({
    description: Yup.string()
      .min(10, 'Descrizione troppo corta')
      .required('Campo obbligatorio'),
    duration: Yup.number()
      .typeError('Numero non valido')
      .min(1, 'Durata non valida')
      .required('Campo obbligatorio'),
    price: Yup.number()
      .typeError('Numero non valido')
      .min(0, 'Prezzo orario non valido')
      .required('Campo obbligatorio'),
  });

  useEffect(() => {
    if (service === undefined) {
      const userServicesIds = user.data.supplier_data.services.map((s) => s.service_id);
      const as = services.filter((s) => !userServicesIds.includes(s.id));

      setAvailableServices(as);
    }
  }, [services]);

  const handleDelete = () => {
    deleteServices(service, () => {
      onClose();
    });
  };

  return (
    <Modal show={visible} onHide={onClose} backdrop="static">
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        <h5>
          {service ? 'Modifica servizio' : 'Aggiungi servizio' }
        </h5>
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">
        <Formik
          validationSchema={ValidationSchema}
          initialValues={{
            description: service ? service.description : '',
            duration: service ? service.duration : '',
            price: service ? formatPrice(service.price, 60) : '',
          }}
          onSubmit={(values) => {
            // const priceParts = splitMulti(values.price, [',', '.']);
            const price = parseFloat(values.price, 10) * 100;
            const action = service ? updateUserService : addUserService;
            const data = service ? {
              ...service,
              ...values,
              price,
            } : {
              service_id: selectedService.id,
              ...values,
              price,
            };

            action(data, () => {
              onClose();
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">

              {service ? (
                <>
                  <p>
                    Stai modificando
                    <b>
                      {' '}
                      {service.name}
                    </b>
                  </p>
                </>
              ) : (
                <>
                  {availableServices !== undefined && availableServices.length > 0 ? (
                    <div className="custom-dropdown-wrapper" style={{ marginBottom: 16 }}>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <CustomInput
                            required
                            value={selectedService !== undefined ? selectedService.name : undefined}
                            id="gender"
                            label="Servizio da aggiungere"
                            variant="outlined"
                            contentEditable={false}
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {availableServices.map((s) => (
                            <Dropdown.Item
                              onSelect={() => setSelectedService(s)}
                            >
                              {s.name}

                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    <p>Non ci sono servizi che puoi aggiungere</p>
                  )}

                </>
              )}

              <Grid container>

                <Grid item xs={12}>
                  <div style={{ marginBottom: 16 }}>
                    <CustomInput
                      multiline
                      required
                      onChange={handleChange('description')}
                      onBlur={handleBlur('description')}
                      value={values.description}
                      id="description"
                      label="Descrizione"
                      variant="outlined"
                      error={touched.description && errors.description}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginBottom: 16 }}>
                    <CustomInput
                      required
                      onChange={handleChange('duration')}
                      onBlur={handleBlur('duration')}
                      value={values.duration}
                      id="duration"
                      label="Durata in minuti"
                      variant="outlined"
                      error={touched.duration && errors.duration}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginBottom: 16 }}>
                    <CustomInput
                      required
                      onChange={handleChange('price')}
                      onBlur={handleBlur('price')}
                      value={values.price}
                      id="price"
                      label="Prezzo orario"
                      variant="outlined"
                      error={touched.price && errors.price}
                    />
                  </div>
                </Grid>
              </Grid>

              <div style={{ textAlign: 'center' }}>
                {service !== undefined && <Button style={{ marginRight: service ? 4 : 0 }} disabled={Object.keys(errors).length > 0 || (availableServices !== undefined && availableServices.length === 0)} onClick={handleDelete} variant="red">Rimuovi</Button>}
                <Button style={{ marginLeft: service ? 4 : 0 }} disabled={Object.keys(errors).length > 0 || (availableServices !== undefined && availableServices.length === 0)} type="submit" onClick={handleSubmit} variant="yellow">Conferma</Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(AddServiceModal);
