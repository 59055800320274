/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Swal from 'sweetalert2';
import Loader from '../../../../components/loader';
import AddPaymentModal from './add-payment-modal';
import colors from '../../../../res/colors';


const PaymentsSettings = ({
  paymentMethods, fetchPaymentMethods, deletePaymentMethods,
}) => {
  const [showModal, setShowModal] = useState();

  useEffect(() => {
    if (paymentMethods === undefined) {
      fetchPaymentMethods();
    }
  }, []);

  if (paymentMethods === undefined) return <Loader />;


  return (
    <div className="settings-content-wrapper account-settings-wrapper">
      {paymentMethods.length > 0 ? paymentMethods.map(
        (paymentMethod) => (
          <div>
            <div className="setting-item-value">{`**** **** **** ${paymentMethod.card.last4}`}</div>
            <div className="setting-item-edit">
              <p
                style={{ color: '#E46304' }}
                onClick={() => {
                  Swal.fire({
                    title: 'Sei sicuro di voler cancellare questo metodo di pagamento?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: colors.secondary.normal,
                    cancelButtonColor: colors.red.normal,
                    confirmButtonText: 'Si, cancella!',
                  }).then((result) => {
                    if (result.value) {
                      deletePaymentMethods(paymentMethod.id);
                    }
                  });
                }}
              >
          Elimina
                <HighlightOffIcon style={{ color: '#E46304', fontSize: 16, marginLeft: 8 }}>filled</HighlightOffIcon>
              </p>
            </div>
          </div>
        ),
      ) : <p>Non hai ancora aggiunto nessun metodo di pagamento</p>}


      <AddCircleIcon
        onClick={() => setShowModal(true)}
        style={{ color: colors.secondary.normal, fontSize: 40, marginTop: 30 }}
      >
        filled
      </AddCircleIcon>


      <AddPaymentModal
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default PaymentsSettings;
