import userReducer from './user.reducers';
import servicesReducer from './services.reducers';
import searchReducer from './search.reducers';
import suppliersReducer from './suppliers.reducers';
import reservationsReducer from './reservations.reducers';
import commonsReducer from './commons.reducers';

// import searchMock from '../mocks/search.json';


export const initialState = {
  user: undefined,
  modal: { visible: false, content: null },
  nestedModal: { visible: false, content: null },
  mainLoading: true,
  calendarFilters: {},
  // search: searchMock,
  // searchResults: searchResultsMock,
  // search: searchMock,
  // services: servicesMock,
  // notifications: [1, 1, 1],
  /*
  search: unloggedSearch,
  supplier: supplierMock,
  reservation: reservationMock,
  savedReservation: reservationMock,
  savedSearch: unloggedSearch,
  savedSupplier: supplierMock,
  */
};

const rootReducer = (state = initialState, action = null) => {
  let newState = state;
  newState = userReducer(newState, action);
  newState = servicesReducer(newState, action);
  newState = searchReducer(newState, action);
  newState = suppliersReducer(newState, action);
  newState = reservationsReducer(newState, action);
  newState = commonsReducer(newState, action);
  return newState;
};

export default rootReducer;
