/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import './style.css';
import { withRouter } from 'react-router-dom';

const InfoLabel = ({
  type = 'warning', text, link, history,
}) => (
  <div
    className={`info-label-wrapper cursorPointer ${type}`}
    onClick={() => {
      if (link) {
        history.push(link);
      }
    }}
  >
    <span>{text}</span>
  </div>
);

export default withRouter(InfoLabel);
